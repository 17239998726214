<template>
  <div class="order-mask" v-if="negative.negativeFlag">
    <!-- 重拍 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div class="mask-title">
          <div></div>
          {{ negative.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <!-- 247原因 3重拍 6更换服装 -->
      <div class="table">
        <!-- 多选 -->
        <table v-if="negative.type == 3 || negative.type == 6">
          <tr>
            <td class="table-td xin2">是否重选服装</td>
            <td colspan="3">
              <el-radio-group v-model="radio">
                <el-radio :label="0" v-if="!(negative.type == 6)">否</el-radio>
                <el-radio :label="1">指定服装</el-radio>
                <el-radio :label="2">客户自选</el-radio>
              </el-radio-group>
            </td>
          </tr>
        </table>
        <div class="ntv">
          <div class="ntv_l" v-if="negative.type == 3 || negative.type == 6">
            <el-input
              v-if="radio != 1"
              type="textarea"
              placeholder="请输入原因"
              v-model="textarea"
              :maxlength="negative.maxlength"
              show-word-limit
            >
            </el-input>
            <!-- 搜索 表格 -->
            <div v-if="radio == 1" class="mask-table">
              <div class="search_nav">
                <el-input
                  v-model="keyword"
                  style="width: 30%"
                  class="search_inp"
                  placeholder="请输入标准片ID"
                  clearable
                ></el-input>
                <el-select
                  :popper-append-to-body="false"
                  v-model="sex"
                  style="width: 15%"
                  placeholder="请选择性别"
                  class="clothing_size"
                  clearable
                >
                  <el-option
                    v-for="item in sex_type"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <el-select
                  :popper-append-to-body="false"
                  v-model="size"
                  style="width: 15%"
                  placeholder="请选择衣码"
                  class="selec"
                  clearable
                >
                  <el-option
                    v-for="item in clothingsize"
                    :key="item.values"
                    :label="item.values"
                    :value="item.values"
                  >
                  </el-option>
                </el-select>
                <!-- 查询按钮 -->
                <el-button
                  class="searchBtn"
                  type="primary"
                  @click="orderGetstandard"
                  >查询</el-button
                >
              </div>
              <el-table
                id=" filei"
                class="center_table"
                height="500"
                ref="multipleTable"
                :data="importArr"
                tooltip-effect="dark"
                style="width: 50vw"
                border
                :header-cell-style="{
                  width: 81.823 + 'vw',
                  height: 5.185 + 'vh',
                  background: '#F9F9F9',
                  fontSize: 0.833 + 'vw',
                  textAlign: 'center',
                }"
                @selection-change="handleSelectionChange"
              >
                <el-table-column
                  type="selection"
                  min-width="5.0px"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="id"
                  label="标准图库ID"
                  min-width="12.0px"
                >
                  <template slot-scope="scope">
                    <span class="aFu_span">{{ scope.row.id }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="sex"
                  label="性别"
                  show-overflow-tooltip
                  min-width="15.0px"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="images"
                  label="客户可见标准片"
                  show-overflow-tooltip
                  min-width="15.0px"
                >
                  <template slot-scope="scope">
                    <div class="aFu" @contextmenu="disableRightClick">
                      <!-- <img style="max-width: 100%;max-height: 3.704vh;" v-for="item in scope.row.images" :key="item"
                                    :src="imgurl + item"> -->
                      <el-image
                        style="max-width: 4.167vw; max-height: 3.704vh"
                        :src="imgurl + scope.row.image"
                        @click="bigimgclick(imgurl + scope.row.image)"
                      >
                      </el-image>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="theme"
                  label="拍摄主题"
                  show-overflow-tooltip
                  min-width="15.0px"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="size"
                  label="衣码"
                  show-overflow-tooltip
                  min-width="12.0px"
                >
                  <template slot-scope="scope">
                    <div class="aFu">
                      {{ scope.row.size }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            class="ntv_r"
            style="width: 100%"
            v-if="
              (negative.type == 3 && radio == 1) ||
              negative.type == 2 ||
              negative.type == 4 ||
              negative.type == 5 ||
              negative.type == 7
            "
          >
            <el-input
              type="textarea"
              v-model="textarea"
              placeholder="请输入原因"
              :maxlength="negative.maxlength"
            ></el-input>
          </div>
        </div>
        <div v-if="negative.btn" class="save" @click="handleSubmit">
          {{ negative.btn }}
        </div>
      </div>
    </div>
    <!-- 预览 -->
    <ImgPreview
      :data="imgpreviewObj"
      @imgpreviewCall="imgpreviewCall"
    ></ImgPreview>
  </div>
</template>
    
<script>
export default {
  name: "FailReason",
  props: ["negative"],
  data() {
    return {
      textarea: "",
      radio: 0,
      // radio: 1,
      importArr: [],

      theme_id: "",
      themestype: [],
      sex: "",
      sex_type: [
        {
          id: -1,
          name: "通用",
        },
        {
          id: 0,
          name: "男",
        },
        {
          id: 1,
          name: "女",
        },
      ],
      size: "",
      clothingsize: [],
      keyword: "", //查询
      multipleSelection: [],

      shopname: "",
      imgpreviewObj: {
        flag: false,
        src: "",
        shopname: "",
      },
    };
  },
  methods: {
    handlerClear() {
      this.keyword = "";
    },
    //得到当前账号所关联的店铺信息
    async indexGetinfo() {
      let res = await this.$api.indexGetinfo();
      if (res.code == 1) {
        //  console.log(res.data);
        this.shopname = res.data.shopname;
      }
    },
    // 阻止默认右键菜单的显示
    disableRightClick(event) {
      event.preventDefault();
      return false;
    },
    bigimgclick(url) {
      this.imgpreviewObj.flag = true;
      this.imgpreviewObj.src = url;
      this.imgpreviewObj.shopname = this.shopname;
    },
    imgpreviewCall() {
      this.imgpreviewObj.flag = false;
    },
    //取消弹窗
    handleRefund() {
      let obj = {
        negativeFlag: false,
        submitFlag: false,
      };
      this.$emit("negativeCell2", obj);
    },
    handleSubmit() {
      if (this.radio == 1) {
        if (this.multipleSelection.length == 0) {
          this.$message.error("请选择标准片");
        } else if (this.multipleSelection.split(",").length == 1) {
          let obj = {
            negativeFlag: false,
            submitFlag: true,
            theme_id: this.multipleSelection,
            radio: this.radio,
            typeText: this.textarea,
          };
          this.$emit("negativeCell2", obj);
        } else if (this.multipleSelection.split(",").length > 1) {
          this.$message.error("请选择一个标准片");
        }
      } else {
        let obj = {
          negativeFlag: false,
          submitFlag: true,
          typeText: this.textarea,
          radio: this.radio,
        };
        this.$emit("negativeCell2", obj);
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = [];
      val.forEach((element) => {
        this.multipleSelection.push(element.id);
      });
      this.multipleSelection =
        JSON.parse(JSON.stringify(this.multipleSelection)) + "";
      //  console.log(this.multipleSelection)
    },
    //衣码
    async standardGetsize() {
      let res = await this.$api.standardGetsize();
      if (res.code == 1) {
        this.clothingsize = res.data;
      }
    },
    //主题
    async standardGettheme() {
      let res = await this.$api.typeIndex();
      if (res.code == 1) {
        let arr = [];
        res.data.forEach((item) => {
          item.sec.forEach((v) => {
            v.third.forEach((vv) => {
              arr.push(vv);
            });
          });
        });
        this.themestype = arr;
      }
    },
    //获取服装列表
    async orderGetstandard() {
      let data = {
        theme_id: this.negative.theme_id,
        bianhao: this.keyword,
        sex: this.sex,
        size: this.size,
        style_id: this.negative.style_id,
      };
      let res = await this.$api.orderGetstandard(data);
      if (res.code == 1) {
        this.importArr = res.data;
        this.importArr.forEach((element) => {
          // element.theme_ids = element.theme_ids + "";

          this.themestype.forEach((item) => {
            if (element.theme_id == item.id) {
              element.theme_id = item.name;
            }
          });
          // element.theme_ids = element.theme_ids.join("/");
        });
      }
    },
  },
  created() {
    this.standardGettheme();
    this.standardGetsize();
    this.indexGetinfo();
  },
  watch: {
    negative: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue.type == 3) {
          this.radio = 0;
        } else if (newValue.type == 6) {
          this.radio = 1;
        }
        this.textarea = newValue.reason;
        console.log(newValue);
      },
    },
    radio(newval, oldVal) {
      if (newval == 1) {
        this.orderGetstandard();
      }
    },
  },
};
</script>
    
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask .mask-main {
  width: auto;
  // height: 828px;
}

.search_nav {
  margin-bottom: 1.852vh;

  > div {
    margin-right: 1%;
  }

  .clear {
    color: #409eff;
    background-color: white;
  }
}

/deep/.ntv_l {
  textarea {
    width: 35vw;
    height: 48.889vh;
    resize: none;
    border: 0.052vw solid#cdcdcd;
    border-radius: 1vw;
    text-indent: 2em;
    line-height: 2em;
  }
}

/deep/.ntv_r {
  width: auto !important;
  padding-top: 1.852vh;
  margin-left: 1.042vw;

  textarea {
    width: 35vw;
    height: 48.889vh;
    resize: none;
    border: 0.052vw solid#cdcdcd;
    border-radius: 1vw;
    text-indent: 2em;
    line-height: 2em;
  }

  /deep/.el-radio-group {
    width: auto !important;
    text-align: left;
    padding-left: 3vw !important;

    .el-radio__label {
      font-size: 0.833vw;
    }
  }
}

.ntv {
  display: flex;
  flex-wrap: nowrap;
}
</style>