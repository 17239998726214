<template>
    <div class="order-mask" v-if="dataVal.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    {{ dataVal.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <table>
                    <tr>
                        <td class="table-td xin2">拍摄主题</td>
                        <td colspan="3">
                            <input type="text" v-model="users.value" placeholder="请输入拍摄主题">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">是否启用</td>
                        <td colspan="3">
                            <div style="padding-left: 1.25vw;">
                                <el-switch v-model="users.switchVal" active-color="#13ce66" inactive-color="#D7D6D6">
                                </el-switch>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">客户填写字段</td>
                        <td colspan="3" style="padding-left: 1.302vw;">
                            <!-- <div class=""> -->
                            <el-checkbox-group v-model="checklist" @change="checklistchange">
                                <el-checkbox v-for="item in themeslist" :key="item.id" :label="item.id">{{
                                    item.name }}</el-checkbox>
                            </el-checkbox-group>
                            <!-- </div> -->
                        </td>
                    </tr>
                </table>
                <div v-if="dataVal.btn != ''" class="save" @click="handleSubmit">{{ dataVal.btn }}</div>
            </div>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "AddAuth",
    props: ["dataVal"],
    data() {
        return {
            themeslist: [{
                id: '1',
                name: "性别"
            },
            {
                id: '3',
                name: "衣服尺码"
            }],

            radio: 3,
            users: {
                value: "",
                switchVal: null,
                themes: "",
            },
            checklist: []
        };
    },
    mounted() {

    },
    methods: {
        checklistchange(e) {
            // console.log(e);
            this.users.themes = ""
            this.checklist = e
            // this.themeslist.forEach(element => {
            //     for (let i = 0; i < this.checklist.length; i++) {
            //         if (this.checklist[i] == element.name) {
            //             this.users.themes.push(element.id)
            //         }
            //     }
            // })
            this.users.themes = this.checklist.toString();
            // console.log(this.users.themes)
            // console.log(e);
        },
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
            }
            this.$emit("addAccountCell", obj);
        },
        handleSubmit() {
            let obj = {
                flag: false,
                typeText: "",
                users: this.users,
            }
            this.$emit("addAccountCell", obj);
        },
    },
    watch: {
        dataVal: {
            deep: true,
            handler(newValue, oldValue) {
                //  console.log(newValue);
                if (newValue.type == 'edit') {
                    this.users.value = newValue.value.name;
                    this.users.switchVal = newValue.value.status;

                    this.checklist = newValue.value.setinfo != null ? newValue.value.setinfo : [];
                    // if (newValue.value.setinfo.setinfoVal != null) {
                    //     let arr = newValue.value.setinfo.setinfoVal.split(',');
                    //     this.checklist = []
                    //     arr.forEach(element => {
                    //         this.themeslist.forEach(item => {
                    //             if (element == item.name) {
                    //                 this.checklist.push(item.id)
                    //             }
                    //         })
                    //     });
                    //   //  console.log(this.checklist);
                    // } else {
                    //     this.checklist = [];
                    // }

                    // console.log(this.checklist);
                } else if (newValue.type == 'add') {
                    this.users.value = ""
                    this.users.switchVal = true
                    this.checklist = []
                }
            }
        }
    }
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask .mask-main {
    width: 32.969vw;
}

.table table {
    width: 32.969vw;
    margin-top: 1.852vh;
}

.table table input {
    padding-left: 1.25vw;
    font-size: 0.833vw;
}

/deep/ .el-input--suffix .el-input__inner {
    padding-left: 1.25vw;
}

.radiotype {
    display: flex;
}
</style>