<template>
    <div class="order-mask" v-if="dataVal.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    {{ dataVal.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <table>
                    <tr>
                        <td class="table-td xin2">购买渠道</td>
                        <td colspan="3">
                            <input type="text" v-model="users.channel" :placeholder="'请输入购买渠道'">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">渠道链接</td>
                        <td colspan="3">
                            <input type="text" v-model="users.link" :placeholder="'请输入渠道链接或粘贴'">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td">备注</td>
                        <td colspan="3">
                            <el-input type="textarea" placeholder="请输入备注" v-model="users.remark" :maxlength="300"
                                show-word-limit>
                            </el-input>
                        </td>
                    </tr>
                </table>
                <div v-if="dataVal.btn != ''" class="save" @click="handleSubmit">{{ dataVal.btn }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AddMaterialLink",
    props: ["dataVal"],
    data() {
        return {
            radio: 3,
            users: {
                channel: "",
                link: "",
                remark: ""
            }
        };
    },
    mounted() {
        if (this.data) {
            if (this.data.type == 'edit') {
                this.users.id = this.data.id;
            }
        }

    },
    methods: {
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                typeText: "",
                submitFlag: false
            }
            this.$emit("addMaterialLinkCell", obj);
        },
        handleSubmit() {
            let obj = {
                flag: false,
                typeText: "",
                users: this.users,
                submitFlag: true
            }
            this.$emit("addMaterialLinkCell", obj);
        },
    },
    watch: {
        dataVal: {
            deep: true,
            handler(newvalue, oldvalue) {
                if (newvalue.id) {
                    this.users = {
                        channel: newvalue.users.channel,
                        link: newvalue.users.link,
                        remark: newvalue.users.remark
                    }
                } else {
                    this.users = {
                        channel: "",
                        link: "",
                        remark: ""
                    }
                }
            }
        }
    }
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/mask.less");

/deep/.el-textarea__inner {
    height: 14.259vh;
    border: none;
    resize: none;
}

.order-mask .mask-main {
    width: 32.969vw;
}

.table table {
    width: 32.969vw;
    margin-top: 1.852vh;
}

.table table input {
    padding-left: 1.25vw;
    font-size: 0.833vw;
}
</style>