<template>
  <div class="order-mask" v-if="data.flag">
    <!-- 上传底片 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div class="mask-title">
          <div></div>
          服装品控用户协议
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="table">
        服装品控用户协议服装品控用户协议服装品控用户协议服装品控用户协议服装品控用户协议服装品控用户协议服装品控用户协议服装品控用户协议
      </div>
    </div>
  </div>
</template>
        
<script>
export default {
  name: "AddAuth",
  props: ["data"],
  data() {
    return {

    };
  },
  created() {
    //  console.log(this.data)
  },
  mounted() {

  },
  methods: {
    //取消弹窗
    handleRefund() {
      this.$emit("handleTipCell", { flag: false });
    },
  },
};
</script>
        
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask .mask-main {
  width: 37.917vw;
  min-height: 80vh;
  max-height: 80vh;
  overflow-y: auto;
}

.table table {
  width: 37.917vw;
  margin-top: 1.852vh;
}

.table table input {
  padding-left: 1.25vw;
  font-size: 0.833vw;
}
</style>