<template>
    <div class="order-mask" v-if="dataVal.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">编辑分类</div>
                <i @click="handleSubmit" class="el-icon-close"></i>
            </div>
            <div class="cont_actable">
                <div class="cont_act_l">
                    <div class="cont_left_title">
                        <div>一级分类</div>
                        <img src="@/assets/images/icon/add.png" alt="" @click="addclass('1')">
                    </div>
                    <div class="routelist">
                        <draggable v-model="activeList" group="people" @change="change" @start="start" @end="end">
                            <div :class="['types', activeName == item.id ? 'types_act' : '']"
                                v-for="(item, index) in activeList" :key="item.id" @click="handleTab(item.id, index)">
                                <div class="types_l">{{ item.name }}</div>
                                <div class="types_r">
                                    <img src="@/assets/images/icon/set/edit.png" alt="" @click.stop
                                        @click="addclass('2', item.id, item.name)">
                                    <img src="@/assets/images/icon/set/remove.png" alt="" @click.stop
                                        @click="handlePushs('1', item.id)">
                                </div>
                            </div>
                        </draggable>
                    </div>
                </div>
                <div class="cont_table_r">
                    <div class="cont_table_title">
                        <div>二级分类</div>
                        <img src="@/assets/images/icon/add.png" alt="" @click="addclass('3')">
                    </div>
                    <div class="table">
                        <el-table class="center_table" ref="multipleTable" :data="tableDatalist" tooltip-effect="dark"
                            height="26.062vw" style="width: 100%" border :header-cell-style="{
                                width: 81.823 + 'vw',
                                height: 5.185 + 'vh',
                                background: '#F9F9F9',
                                fontSize: 0.729 + 'vw',
                                textAlign: 'center',
                            }" @selection-change="handleSelectionChange">
                            <el-table-column align="center" type="index" label="序号" min-width="7.3px">
                            </el-table-column>
                            <el-table-column align="center" prop="name" label="名称" min-width="19.4px">
                            </el-table-column>
                            <el-table-column align="center" prop="name_en" label="英文名称" min-width="23.4px">
                            </el-table-column>
                            <el-table-column align="center" prop="image" label="介绍图" min-width="15.3px">
                                <template slot-scope="scope">
                                    <div class="aFu" @contextmenu="disableRightClick">
                                        <!-- <img style="max-width: 100%;max-height: 3.704vh;" :src="imgurl + scope.row.images"> -->
                                        <el-image v-if="scope.row.image != '' && scope.row.image != null"
                                            style="max-width: 4.167vw;max-height: 3.704vh;" :src="imgurl + scope.row.image"
                                            @click="bigimgclick(imgurl + scope.row.image)">
                                        </el-image>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="显示状态" min-width="19.4px">
                                <template slot-scope="scope">
                                    <el-switch v-model="scope.row.status"
                                        @change="handlePushs('2', scope.row.id, scope.row.status)" active-color="#13ce66"
                                        inactive-color="#D7D6D6">
                                    </el-switch>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="操作" min-width="19.4px">
                                <template slot-scope="scope">
                                    <div class="aFu">
                                        <span class="aFu_span" @click="addclass('4', scope.row)">编辑</span>
                                        <span class="aFu_span" @click="handlePushs('3', scope.row.id)">删除</span>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- 底部分页 -->
                    <!-- <div class="footer-page">
                        <span>共{{ tableData.total_count }}条</span>
                        <el-pagination popper-class="paging" @size-change="handleSizeChange"
                            @current-change="handleCurrentChange" background :current-page="page"
                            :page-sizes="[10, 20, 30, 40]" :page-size="count" layout="sizes, prev, pager, next, jumper"
                            :total="tableData.total_count" :pager-count="5">
                        </el-pagination>
                    </div> -->
                </div>
            </div>
            <div class="footer">
                <div class="confirm" @click="handleSubmit">保存</div>
                <div class="confirm cancel" @click="handleSubmit">取消</div>
            </div>
        </div>
        <!-- 确定 -->
        <MaskPack :data="pushObj" @childRef="handlePushsCall"></MaskPack>
        <!-- 预览 -->
        <ImgPreview :data="imgpreviewObj" @imgpreviewCall="imgpreviewCall"></ImgPreview>
        <!-- 一级分类 二级分类 -->
        <FirstlevelClassification :dataVal="classificationObj" @classificationCall="classificationCall">
        </FirstlevelClassification>
    </div>
</template>
      
<script>
import draggable from 'vuedraggable'
export default {
    name: "EditClassification",
    props: ["dataVal"],
    components: {
        draggable,
    },
    data() {
        return {
            //表格切换
            activeName: '',
            cate_id: '-1',
            activeListids: [],
            activeList: [{
                child: []
            }],
            pushObj: {

            },
            imgpreviewObj: {
                flag: false,
                src: '',
                shopname: ''
            },
            shopname: '',
            classificationObj: {
                flag: false,
                title: '新增二级分类',
                title1: '分类名称',
                maxlength1: 8,
                title2: '英文名称',
                maxlength2: 20,
                title3: '介绍图',
                btn: '保存',
                btn1: '取消',
            },
            multipleTable: [],
            tableDatalist: [],

            dragStartid: '',
        };
    },
    mounted() {
        this.indexGetinfo()
    },
    methods: {
        // 监听拖拽
        change(event) {
            // console.log("change");
            // console.log(event);
            // console.log(this.myArray);
        },
        // 开始拖拽
        start(event) {
            // console.log("start");
            // console.log(event);

        },
        // 结束拖拽
        end(event) {
            // console.log("end");
            // event.item  拖拽的本身
            // event.to      拖拽的目标列表
            // event.from    拖拽之前的列表
            // event.oldIndex    拖拽前的位置
            // event.newIndex    拖拽后的位置
            // console.log(event);
            if (event.oldIndex != event.newIndex) {
                this.activeListids = []
                this.activeList.forEach((item, index) => {
                    this.activeListids.push(item.id)
                })
                this.activeListids.forEach((item, index) => {
                    if (item == this.activeName) {
                        this.cate_id = index
                    }
                })
                this.spreadSetcateorder()
            }
        },
        //tab切换
        handleTab(id, i) {
            this.activeName = id;
            this.cate_id = i
            this.spreadCatelist()
        },
        //得到当前账号所关联的店铺信息
        async indexGetinfo() {
            let res = await this.$api.indexGetinfo();
            if (res.code == 1) {
                this.shopname = res.data.shopname
            }
        },
        // 阻止默认右键菜单的显示
        disableRightClick(event) {
            event.preventDefault();
            return false
        },
        bigimgclick(url) {
            this.imgpreviewObj.flag = true
            this.imgpreviewObj.src = url
            this.imgpreviewObj.shopname = this.shopname
        },
        imgpreviewCall() {
            this.imgpreviewObj.flag = false
        },
        handlePushs(type, id, status) {
            if (type == 1) {
                this.pushObj = {
                    flag: true,//是否显示
                    title: "删除提醒",//标题
                    text: "确认要删除该一级风格分类吗？",//内容
                    btns: "确定",//确定按钮
                    btnq: "关闭",//取消按钮
                    type: type,
                    id: id
                }
            } else if (type == 2) {
                //  console.log(status);
                if (status) {
                    this.pushObj = {
                        flag: true,//是否显示
                        title: "启用",//标题
                        text: "确认要启用该风格分类吗？",//内容
                        btns: "确定",//确定按钮
                        btnq: "关闭",//取消按钮
                        type: type,
                        status: status ? 1 : 0,
                        id: id
                    }
                } else {
                    this.pushObj = {
                        flag: true,//是否显示
                        title: "禁用",//标题
                        text: "确认要禁用该二级风格分类吗？",//内容
                        btns: "确定",//确定按钮
                        btnq: "关闭",//取消按钮
                        type: type,
                        status: status ? 1 : 0,
                        id: id
                    }
                }
            } else if (type == 3) {
                this.pushObj = {
                    flag: true,//是否显示
                    title: "删除提醒",//标题
                    text: "确认要删除该风格分类吗？",//内容
                    btns: "确定",//确定按钮
                    btnq: "关闭",//取消按钮
                    type: type,
                    id: id
                }
            }
        },
        async handlePushsCall(obj) {
            if (obj.submitFlag) {
                if (this.pushObj.type == 1) {
                    let res = await this.$api.spreadDelcate({
                        id: this.pushObj.id
                    })
                    if (res.code == 1) {
                        if (this.activeName == this.pushObj.id) {
                            this.activeName = ''
                            this.cate_id = '-1'
                        }
                        this.spreadCatelist()
                        this.pushObj.flag = false
                    }
                } else if (this.pushObj.type == 2) {
                    let res = await this.$api.spreadSetcatestatus({
                        id: this.pushObj.id,
                        status: this.pushObj.status
                    })
                    if (res.code == 1) {
                        this.spreadCatelist()
                        this.pushObj.flag = false
                    }
                } else if (this.pushObj.type == 3) {
                    let res = await this.$api.spreadDelcate({
                        id: this.pushObj.id
                    })
                    if (res.code == 1) {
                        this.spreadCatelist()
                        this.pushObj.flag = false
                    }
                }
            } else {
                if (this.pushObj.type == 2) {
                    this.tableDatalist.forEach(item => {
                        if (item.id == this.pushObj.id) {
                            item.status = !item.status;
                        }
                    })
                }
                this.pushObj.flag = false
            }
        },
        addclass(type, id, name) {
            if (type == 1) {
                this.classificationObj = {
                    flag: true,
                    title: '新增一级分类',
                    title1: '分类名称',
                    maxlength1: 8,
                    btn: '保存',
                    btn1: '取消',
                    type: type
                }
            } else if (type == 2) {
                this.classificationObj = {
                    flag: true,
                    title: '编辑一级分类',
                    title1: '分类名称',
                    maxlength1: 8,
                    btn: '保存',
                    btn1: '取消',
                    obj: {
                        id: id,
                        name: name
                    },
                    type: type
                }
            } else if (type == 3) {
                if (this.activeName == '') {
                    this.$message.error('请先选择一级分类');
                    return false;
                }
                this.classificationObj = {
                    flag: true,
                    title: '新增二级分类',
                    title1: '分类名称',
                    maxlength1: 8,
                    title2: '英文名称',
                    maxlength2: 20,
                    title3: '介绍图',
                    btn: '保存',
                    btn1: '取消',
                    type: type
                }
            } else if (type == 4) {
                this.classificationObj = {
                    flag: true,
                    title: '编辑二级分类',
                    title1: '分类名称',
                    maxlength1: 8,
                    title2: '英文名称',
                    maxlength2: 20,
                    title3: '介绍图',
                    btn: '保存',
                    btn1: '取消',
                    obj: id,
                    type: type
                }
            }
        },
        async classificationCall(obj) {
            if (obj.submitFlag) {
                if (this.classificationObj.type == 1) {
                    let res = await this.$api.spreadCateedit({
                        name: obj.data.name,
                    });
                    if (res.code == 1) {
                        this.spreadCatelist()
                        this.classificationObj.flag = false;
                        this.$message.success('一级分类新增成功');
                    }
                } else if (this.classificationObj.type == 2) {
                    let res = await this.$api.spreadCateedit({
                        id: obj.data.id,
                        name: obj.data.name,
                    });
                    if (res.code == 1) {
                        this.spreadCatelist()
                        this.classificationObj.flag = false;
                        this.$message.success('一级分类修改成功');
                    }
                } else if (this.classificationObj.type == 3) {
                    let res = await this.$api.spreadCateedit({
                        name: obj.data.name,
                        name_en: obj.data.name_en,
                        image: obj.data.image,
                        status: obj.data.status,
                        pid: this.activeName,
                    });
                    if (res.code == 1) {
                        this.spreadCatelist()
                        this.classificationObj.flag = false;
                        this.$message.success('二级分类新增成功');
                    }
                } else if (this.classificationObj.type == 4) {
                    let res = await this.$api.spreadCateedit({
                        name: obj.data.name,
                        name_en: obj.data.name_en,
                        image: obj.data.image,
                        status: obj.data.status,
                        id: obj.data.id,
                    });
                    if (res.code == 1) {
                        this.spreadCatelist()
                        this.classificationObj.flag = false;
                        this.$message.success('二级分类修改成功');
                    }
                }
            } else {
                this.classificationObj.flag = false;
            }
        },
        // //取消弹窗
        // handleRefund() {
        //     let obj = {
        //         flag: false,
        //     }
        //     this.$emit("editclassificationCall", obj);
        // },
        handleSubmit() {
            // let list = []
            // this.activeList.forEach(item => {
            //     if (item.child.length == 0) {
            //         list.push(item);
            //     }
            // })
            // if (list.length == 0) {
            //     let obj = {
            //         flag: false,
            //     }
            //     this.$emit("editclassificationCall", obj);
            // } else {
            //     let namelist = []
            //     list.forEach(item => {
            //         namelist.push(item.name)
            //     })

            //     let names = JSON.parse(JSON.stringify(namelist)) + ''
            //     this.$message.warning(`请完善一级分类  ${names}  的二级分类，确保每个一级分类都有二级分类`);
            // }
            let obj = {
                flag: false,
            }
            this.$emit("editclassificationCall", obj);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 分类排序
        async spreadSetcateorder() {
            let res = await this.$api.spreadSetcateorder({
                idarr: this.activeListids
            })
            if (res.code == 1) {
                this.$message.success(res.msg)
                this.spreadCatelist()
            }
        },
        async spreadCatelist() {
            let res = await this.$api.spreadCatelist()
            if (res.code == 1) {
                this.activeList = res.data

                this.activeListids = []
                this.activeList.forEach(item => {
                    this.activeListids.push(item.id)
                })

                if (this.cate_id == '-1') {
                    this.tableDatalist = []
                    res.data.forEach(item => {
                        this.tableDatalist.push(item.child)
                    })
                    this.tableDatalist = this.tableDatalist.flat()
                } else {
                    this.tableDatalist = res.data[this.cate_id].child
                }
                this.tableDatalist.forEach(item => {
                    item.status = item.status == 1 ? true : false
                })
            }
        }
    },
    watch: {
        dataVal: {
            deep: true,
            handler(newvalue, oldvalue) {
                this.authArr = [newvalue.obj]
                this.spreadCatelist()
            }
        }
    }
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");
@import url("@/assets/css/table.less");

.order-mask .mask-main {
    width: 69.688vw;
    box-sizing: border-box;
    padding: 0;

    .mask-nav {
        height: 3.646vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1.5625vw;
        background-color: #0f5df9;
        border-radius: 1.296vh 0.521vw 0 0;
        border: 0;

        .mask-title {
            font-family: MicrosoftYaHei-Bold;
            font-size: 0.885vw;
            letter-spacing: .0469vw;
            color: #ffffff;
        }

        i {
            font-family: MicrosoftYaHeiLight;
            font-size: 1.25vw;
            color: #ffffff;
        }
    }

    .cont_actable {
        width: 100%;
        display: flex;
        background-color: #fff;
        padding: 0.521vw;
        display: flex;
        justify-content: space-between;

        .cont_act_l {
            width: 12.5vw;
            height: 30.021vw;
            background-color: #fff;
            display: flex;
            flex-direction: column;

            .cont_left_title {
                width: 12.5vw;
                height: 2.917vw;
                background-color: #e6e6e6;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 0.885vw 0 1.354vw;

                div {
                    font-family: MicrosoftYaHei-Bold;
                    font-size: 0.833vw;
                    letter-spacing: 0.042vw;
                    color: #333333;
                }

                img {
                    width: 1.25vw;
                    height: 1.25vw;
                    cursor: pointer;
                }
            }

            .routelist {
                width: 100%;
                height: 27.104vw;
                display: flex;
                flex-direction: column;
                overflow-y: scroll;

                .types {
                    width: 100%;
                    height: 2.188vw;
                    background-color: #fff;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    padding: 0 0.885vw 0 1.354vw;

                    .types_l {
                        font-family: MicrosoftYaHei;
                        font-size: 0.833vw;
                        letter-spacing: 0.042vw;
                        color: #333333;
                    }

                    .types_r {
                        img {
                            cursor: pointer;
                        }

                        img:nth-child(1) {
                            width: 0.729vw;
                            height: 0.729vw;
                        }

                        img:nth-child(2) {
                            width: 0.833vw;
                            height: 0.833vw;
                            margin-left: 1.042vw;
                        }
                    }
                }

                .types_act {
                    background-color: #edf3ff;

                    .types_l {
                        color: #0f5df9;
                    }
                }
            }
        }

        .cont_table_r {
            width: 55.625vw;
            height: 30.021vw;
            background-color: #fff;

            .cont_table_title {
                width: 100%;
                height: 2.917vw;
                background-color: #e6e6e6;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 1.042vw 0 1.563vw;

                div {
                    font-family: MicrosoftYaHei-Bold;
                    font-size: 0.833vw;
                    letter-spacing: 0.042vw;
                    color: #333333;
                }

                img {
                    width: 1.302vw;
                    height: 1.25vw;
                    cursor: pointer;
                }
            }

            .table {
                width: 100%;
                height: 26.062vw;
                padding: 0.521vw;
                background-color: #f7f7f7;

                .aFu {
                    display: flex;
                    justify-content: space-around;
                }
            }
        }
    }

    .footer {
        width: 100%;
        height: 3.208vw;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 0.521vw;

        .confirm {
            width: 6.302vw;
            height: 2.448vw;
            font-family: MicrosoftYaHei;
            font-size: 0.885vw;
            letter-spacing: 0.047vw;
            color: #ffffff;
            line-height: 2.448vw;
            text-align: center;
            background-color: #2c72ff;
            border-radius: 0.521vw;
            cursor: pointer;
        }

        .cancel {
            color: #2c72ff;
            background-color: #ffffff;
            border: solid 0.052vw #2c72ff;
            margin-left: 1.51vw;
        }
    }
}



.aFu_span {
    color: #2C72FF;
    cursor: pointer;
}

.aFu_span img {
    max-width: 15.729vw;
    max-height: 9.259vh;
    height: auto;
    width: auto;
    display: block;
}
</style>