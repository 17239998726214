<template>
  <!-- 拍摄完成/拍摄未完成 -->
  <div v-if="data.capFlag" class="mask">
    <div class="mask-main">
      <div class="mask-header">
        <div class="mask-left">拍摄进程</div>
      </div>
      <div class="mask-line"></div>
      <div class="mask-table">
        <div class="mask_t_f">
          <div class="mask-lang">服装列表</div>
          <button v-if="clothstatus" class="btn-color2" @click="handlepushcloth('8')">
            再次推送服装
          </button>
        </div>
        <el-table class="center_table" max-height="500" ref="multipleTable" :data="importArr" tooltip-effect="dark"
          style="width: 100%" border :header-cell-style="{
            width: 81.823 + 'vw',
            height: 5.185 + 'vh',
            background: '#F9F9F9',
            fontSize: 0.833 + 'vw',
            textAlign: 'center',
          }" @selection-change="handleSelectionChange">
          <el-table-column type="selection" min-width="4.8px" align="center">
          </el-table-column>
          <el-table-column align="center" prop="id" label="标准图库ID" min-width="13.9px">
            <template slot-scope="scope">
              <span class="aFu_span" @click="handlecalmetriceTo(scope.row.standard_id)">{{ scope.row.bianhao }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="name" align="center" label="标准片名称" show-overflow-tooltip
                        min-width="17.0px"></el-table-column> -->
          <el-table-column align="center" prop="materials" label="关联物资" show-overflow-tooltip min-width="9.6px">
            <template slot-scope="scope">
              <span class="aFu_span" @click="handleSub(scope.row.materials)">查看</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="images" label="客户可见标准片" show-overflow-tooltip min-width="15.0px">
            <template slot-scope="scope">
              <div class="aFu" @contextmenu="disableRightClick">
                <el-image style="
                    max-width: 4.167vw !important;
                    max-height: 3.704vh !important;
                    width: auto !important;
                    height: auto !important;
                  " :src="imgurl + scope.row.images" @click="bigimgclick(imgurl + scope.row.images)">
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="status" label="拍摄状态" show-overflow-tooltip
            min-width="9.0px"></el-table-column>
          <el-table-column align="center" prop="is_renew" label="是否重拍" show-overflow-tooltip
            min-width="9.0px"></el-table-column>
          <el-table-column align="center" prop="theme" label="拍摄主题" show-overflow-tooltip
            min-width="20.0px"></el-table-column>
          <el-table-column align="center" prop="age" label="适用年龄" show-overflow-tooltip
            min-width="15.0px"></el-table-column>
          <el-table-column label="操作" align="center" min-width="35.0px">
            <!--已拍摄未重拍            重拍
                已拍摄已重拍            重拍、查看重拍原因
                未拍摄未重拍            拍摄未完成、拍摄完成、更换服装
                未拍摄已重拍            拍摄未完成、拍摄完成、更换服装、查看重拍原因
                拍摄未完成未重拍        拍摄未完成、拍摄完成、更换服装、查看未完成原因
                拍摄未完成已重拍        拍摄未完成、拍摄完成、更换服装、查看未完成原因、查看重拍原因 -->
            <template slot-scope="scope">
              <div class="aFu">
                <span class="aFu_span" @click="handlepaishe('1', scope.row.id)"
                  v-if="scope.row.status != '已拍摄'">拍摄完成</span>
                <span class="aFu_span" @click="
                  handlepaishe('2', scope.row.id, scope.row.nofinish_reason)
                  " v-if="scope.row.status != '已拍摄'">拍摄未完成</span>
                <span class="aFu_span" @click="handlepaishe('3', scope.row.id, scope.row.theme_id)"
                  v-if="scope.row.status == '已拍摄'">重拍</span>
                <span class="aFu_span" @click="
                  handlepaishe('4', scope.row.id, scope.row.nofinish_reason)
                  " v-if="scope.row.status == '拍摄未完成'">查看未完成原因</span>
                <span class="aFu_span" @click="
                  handlepaishe('7', scope.row.id, scope.row.renew_reason)
                  " v-if="scope.row.is_renew == '是'">查看重拍原因</span>
                <span class="aFu_span" @click="
                  handlepaishe('6', scope.row.id, scope.row.theme_id, scope.row.style_id)"
                  v-if="scope.row.status != '已拍摄'">更换服装</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="mask-foot">
        <button class="btn-color" @click="handlecg">拍摄完成</button>
        <button @click="handlepaishe('5')">拍摄未完成</button>
        <button @click="handleClose">取消</button>
      </div>
    </div>
    <!-- 确认 -->
    <MaskPack :data="pushObj" @childRef="handlePushsCall"></MaskPack>
    <!-- 查看原因 重拍原因-->
    <FailReason :negative="reason" @negativeCell2="negativeCell2"></FailReason>
    <!-- 物资列表 -->
    <Substances :data="subObj" @substancesCell="substancesCell"></Substances>
    <!-- 预览 -->
    <ImgPreview :data="imgpreviewObj" @imgpreviewCall="imgpreviewCall"></ImgPreview>
  </div>
</template>

<script>
//重拍，服装更换
import FailReason from "@/components/management/FailReason.vue";
//重拍
import Substances from "@/components/management/Substances.vue";
export default {
  name: "capture",
  props: ["data"],
  data() {
    return {
      importArr: [],
      importarr: [],
      multipleSelection: [],
      //查看原因
      reason: {
        negativeFlag: false,
        title: "拍摄未完成原因",
        name: "",
        btn: "保存",
        maxlength: 300,
        type: 1, //1:拍摄未完成原因 2:查看原因 3:重拍
      },
      //重拍
      subObj: {
        flag: false,
        title: "查看物资",
        name: "",
        id: "",
      },
      statustype: [
        {
          id: 0,
          name: "未拍摄",
        },
        {
          id: 1,
          name: "已拍摄",
        },
        {
          id: 2,
          name: "拍摄未完成",
        },
      ],
      pushObj: {
        flag: false, //是否显示
        title: "", //标题
        text: "", //内容
        btns: "确定", //确定按钮
        btnq: "关闭", //取消按钮
      },

      themestype: [],
      clothstatus: false, // 预约单状态

      shopname: "",
      imgpreviewObj: {
        flag: false,
        src: "",
        shopname: "",
      },
    };
  },
  mounted() {
  },
  methods: {
    /* 绘制图片 */
    async CopyImg(url) {
      if (url == null || url == '') {
        return false
      }
      // const canvas = document.createElement('canvas');
      // const image = new Image();
      // image.setAttribute('crossOrigin', 'Anonymous'); // 可能会有跨越问题
      // image.src = url;
      // console.log(image.src)
      // image.onload = () => {
      //   canvas.width = image.width;
      //   canvas.height = image.height;
      //   canvas.getContext('2d').drawImage(image, 0, 0); // 加载img到画板
      //   const url = canvas.toDataURL('image/png'); // 转换图片为dataURL，格式为png
      //   console.log(url)
      //   this.clipboardImg(url); // 调用复制方法
      // };
      let res = await this.$api.indexSetimage({
        image: this.imgurl + url
      })
      if (res.code == 1) {
        this.clipboardImg(res.data)
      }
    },
    /* 复制图片 */
    async clipboardImg(url) {
      try {
        const data = await fetch(url);
        const blob = await data.blob();
        await navigator.clipboard.write([
          new window.ClipboardItem({
            [blob.type]: blob
          })
        ]);
        this.$message({
          type: 'success',
          message: '复制成功'
        });
      } catch (err) {
        this.$message({
          type: 'warning',
          message: '复制失败'
        });
      }
    },
    //得到当前账号所关联的店铺信息
    async indexGetinfo() {
      let res = await this.$api.indexGetinfo();
      if (res.code == 1) {
        //  console.log(res.data);
        this.shopname = res.data.shopname;
      }
    },
    // 阻止默认右键菜单的显示
    disableRightClick(event) {
      event.preventDefault();
      return false;
    },
    bigimgclick(url) {
      this.imgpreviewObj.flag = true;
      this.imgpreviewObj.src = url;
      this.imgpreviewObj.shopname = this.shopname;
    },
    imgpreviewCall() {
      this.imgpreviewObj.flag = false;
    },
    handlecalmetriceTo(id) {
      this.$router.push({ path: "/CalmetricsDetail", query: { id: id } });
    },
    //关闭弹窗
    handleClose() {
      let obj = {
        capFlag: false,
      };
      // console.log(obj);
      this.$emit("capCallName", obj);
    },
    // 再次推送 筛选服装
    handlepushcloth(type) {
      this.pushObj.type = type;
      this.pushObj.ids = this.multipleSelection;
      this.pushObj.title = "再次推送 "; //标题
      this.pushObj.text = "确定对拍摄未完成的订单进行再次推送并重新选衣吗？"; //内容
      this.pushObj.flag = true;
    },
    //拍摄设置完成
    handlecg() {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请选择服装");
      } else {
        this.pushObj.flag = true;
        this.pushObj.type = 2;
        this.pushObj.ids = this.multipleSelection;
        this.pushObj.title = "批量设置拍摄完成"; //标题
        this.pushObj.text = "确定批量设置拍摄完成吗？"; //内容
      }
    },
    //拍摄设置完成回调
    async handlePushsCall(obj) {
      if (obj.submitFlag) {
        if (this.pushObj.type == 1) {
          let data = {
            order_id: this.data.id,
            ids: this.pushObj.ids,
            status: "1",
          };
          let res = await this.$api.orderSetfinish(data);
          if (res.code == 1) {
            this.pushObj.flag = false;
            this.orderProgresslist();
          }
        } else if (this.pushObj.type == 2) {
          let data = {
            order_id: this.data.id,
            ids: this.pushObj.ids,
            status: "1",
          };
          let res = await this.$api.orderSetfinish(data);
          if (res.code == 1) {
            this.pushObj.flag = false;
            this.orderProgresslist();
          }
        } else if (this.pushObj.type == 8) {
          let res = await this.$api.orderRetuisong({
            order_id: this.data.id,
          });
          if (res.code == 1) {
            this.pushObj.flag = false;
            this.orderProgresslist();
          }
        }
      } else {
        this.pushObj.flag = false;
      }
    },
    async handlepaishe(type, id, reason, style_id) {
      //单个拍摄完成
      if (type == 1) {
        this.pushObj.flag = true;
        this.pushObj.type = type;
        this.pushObj.ids = id;
        this.pushObj.title = "确定设置拍摄完成"; //标题
        this.pushObj.text = "确定设置该标准片拍摄完成吗？"; //内容
      }
      //拍摄未完成
      else if (type == 2) {
        this.reason.title = "拍摄未完成原因";
        this.reason.id = id;
        this.reason.type = type;
        this.reason.reason = "";
        this.reason.btn = "保存";
        this.reason.negativeFlag = true;
      }
      // 点击重拍
      else if (type == 3) {
        this.reason.title = "重拍原因";
        this.reason.id = id;
        this.reason.type = type;
        this.reason.reason = "";
        this.reason.theme_id = reason;
        this.reason.btn = "保存";
        this.reason.negativeFlag = true;
      }
      //查看原因
      else if (type == 4) {
        this.reason.title = "查看拍摄未完成原因";
        delete this.reason.btn;
        this.reason.id = id;
        this.reason.type = type;
        this.reason.reason = reason;
        this.reason.negativeFlag = true;
      }
      // 设置拍摄未完成
      else if (type == 5) {
        if (this.multipleSelection.length > 0) {
          this.reason.title = "拍摄未完成原因";
          this.reason.type = type;
          this.reason.reason = "";
          this.reason.ids = this.multipleSelection;
          this.reason.btn = "保存";
          this.reason.negativeFlag = true;
        } else {
          this.$message.error("请选择需要设置拍摄未完成原因的订单");
        }
      }
      // 更换服装
      else if (type == 6) {
        let theme_id = reason + "";
        console.log("theme_id", theme_id);
        if (theme_id.charAt(0) == ",") {
          theme_id = theme_id.slice(1, theme_id.length);
        }
        if (theme_id.charAt(theme_id.length - 1) == ",") {
          theme_id = theme_id.slice(0, theme_id.length - 1);
        }
        this.reason.title = "更换服装";
        this.reason.id = id;
        this.reason.type = type;
        this.reason.reason = "";
        this.reason.theme_id = theme_id;
        this.reason.btn = "保存";
        this.reason.negativeFlag = true;
        this.reason.style_id = style_id;
      } else if (type == 7) {
        this.reason.title = "查看重拍原因";
        this.reason.id = id;
        this.reason.type = type;
        this.reason.reason = reason;
        delete this.reason.btn;
        this.reason.negativeFlag = true;
      }
    },
    // 拍摄未完成原因 重拍原因
    async negativeCell2(obj) {
      if (obj.submitFlag) {
        //拍摄未完成原因
        if (this.reason.type == 2) {
          let data = {
            order_id: this.data.id,
            ids: this.reason.id,
            nofinish_reason: obj.typeText,
            status: "2",
          };
          let res = await this.$api.orderSetfinish(data);
          if (res.code == 1) {
            this.reason.negativeFlag = obj.negativeFlag;
            this.orderProgresslist();
          }
          //重拍
        } else if (this.reason.type == 3) {
          if (obj.radio == 0) {
            let data = {
              id: this.reason.id,
              is_xuan: obj.radio,
              renew_reason: obj.typeText,
            };
            let res = await this.$api.setrenew(data);
            if (res.code == 1) {
              this.reason.negativeFlag = obj.negativeFlag;
              let resv = await this.orderProgresslist();
              this.importArr.forEach(item => {
                if (item.id == this.reason.id) {
                  this.CopyImg(item.repaiqrcode)
                }
              })
            }
          } else if (obj.radio == 1) {
            let data = {
              // order_id: this.data.id,
              id: this.reason.id,
              is_xuan: obj.radio,
              standard_id: obj.theme_id,
              renew_reason: obj.typeText,
            };
            let res = await this.$api.setrenew(data);
            if (res.code == 1) {
              this.reason.negativeFlag = obj.negativeFlag;
              let resv = await this.orderProgresslist();
              this.importArr.forEach(item => {
                if (item.id == this.reason.id) {
                  this.CopyImg(item.repaiqrcode)
                }
              })
            }
          } else if (obj.radio == 2) {
            let data = {
              id: this.reason.id,
              is_xuan: obj.radio,
              renew_reason: obj.typeText,
            };
            let res = await this.$api.setrenew(data);
            if (res.code == 1) {
              this.reason.negativeFlag = obj.negativeFlag;
              let resv = await this.orderProgresslist();
              this.importArr.forEach(item => {
                if (item.id == this.reason.id) {
                  this.CopyImg(item.repaiqrcode)
                }
              })
            }
          }
          //查看拍摄未完成原因
        } else if (this.reason.type == 4) {
          this.reason.negativeFlag = obj.negativeFlag;
          //设置拍摄未完成
        } else if (this.reason.type == 5) {
          let data = {
            order_id: this.data.id,
            ids: this.multipleSelection,
            nofinish_reason: obj.typeText,
            status: "2",
          };
          //  console.log(data);
          let res = await this.$api.orderSetfinish(data);
          if (res.code == 1) {
            this.reason.negativeFlag = obj.negativeFlag;
            this.orderProgresslist();
          }
          //更换服装
        } else if (this.reason.type == 6) {
          if (obj.radio == 1) {
            let data = {
              // order_id: this.data.id,
              id: this.reason.id,
              is_xuan: obj.radio,
              standard_id: obj.theme_id,
            };
            let res = await this.$api.orderRenewxuan(data);
            if (res.code == 1) {
              this.reason.negativeFlag = obj.negativeFlag;
              let resv = await this.orderProgresslist();
              this.importArr.forEach(item => {
                if (item.id == this.reason.id) {
                  this.CopyImg(item.changeqrcode)
                }
              })
            }
          } else if (obj.radio == 2) {
            let data = {
              id: this.reason.id,
              is_xuan: obj.radio,
            };
            let res = await this.$api.orderRenewxuan(data);
            if (res.code == 1) {
              this.reason.negativeFlag = obj.negativeFlag;
              let resv = await this.orderProgresslist();
              this.importArr.forEach(item => {
                if (item.id == this.reason.id) {
                  this.CopyImg(item.changeqrcode)
                }
              })
            }
          }
        }
      } else {
        this.reason.negativeFlag = obj.negativeFlag;
      }
    },
    //查看原因
    handleReason3() {
      let obj = {
        negativeFlag: true,
        title: "查看拍摄未完成原因",
        name: "",
        btn: "",
        maxlength: 300,
        type: 2, //1:拍摄未完成原因 2:查看原因 3:重拍
      };
      this.reason = { ...obj };
    },
    handleReason4() {
      let obj = {
        negativeFlag: true,
        title: "查看重拍原因",
        name: "",
        btn: "保存",
        maxlength: 300,
        type: 3, //1:拍摄未完成原因 2:查看原因 3:重拍
      };
      this.reason = { ...obj };
    },
    //重拍
    handleSub(obj) {
      this.subObj.flag = true;
      if (obj) {
        let arr = obj;
        arr.forEach((element) => {
          element.createtime = this.$FilDate.filDate(element.createtime * 1000);
        });
        this.subObj.obj = arr;
      } else {
        if (this.subObj.obj) {
          delete this.subObj.obj;
        }
      }
    },
    //重拍回调
    substancesCell(obj) {
      this.subObj.flag = obj.flag;
    },
    handleSelectionChange(val) {
      this.multipleSelection = [];
      val.forEach((element) => {
        this.multipleSelection.push(element.id);
      });
      this.multipleSelection =
        JSON.parse(JSON.stringify(this.multipleSelection)) + "";
      //  console.log(this.multipleSelection)
    },
    async orderProgresslist() {
      if (this.data.id) {
        let data = {
          id: this.data.id,
        };
        let res = await this.$api.orderProgresslist(data);
        if (res.code == 1) {
          res.data.forEach((element) => {
            this.statustype.forEach((item) => {
              if (item.id == element.status) {
                element.status = item.name;
              }
            });
            element.is_renew = element.is_renew == 0 ? "否" : "是";
            if (element.images) {
              element.images = element.images.split(",")[0] + "";
            }
          });
          this.importArr = res.data;
          this.importarr = res.data;
        }
      }
    },
  },
  components: {
    FailReason,
    Substances,
  },
  created() {
    this.orderProgresslist();
    this.indexGetinfo();
  },
  watch: {
    data: {
      deep: true,
      handler(newval, oldVal) {
        if (newval.status == "拍摄未完成") {
          this.clothstatus = true;
        }
        this.orderProgresslist();
      },
    },
    importArr: {
      deep: true,
      handler(newval, oldVal) {
        this.clothstatus = false;
        newval.forEach((element) => {
          if (element.status == "拍摄未完成") {
            this.clothstatus = true;
          }
        });
      },
    },
  },
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/maskCap.less");

/deep/.el-image__inner {
  max-width: 4.167vw !important;
  max-height: 3.704vh !important;
  height: auto !important;
  width: auto !important;
}

.btn-color2 {
  min-width: 6.25vw;
  height: 4.259vh;
  background-color: #fff;
  color: #2c72ff;
  padding: 0.093vh 0.313vw;
  border-radius: 0.521vw;
  border: solid 0.052vw #2c72ff;
  float: right;
}

.mask_t_f {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mask-lang {
  margin-top: 2.222vh;
}
</style>