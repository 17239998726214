<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 去充值 -->
        <div class="mask-main">
            <div class="mask-cen">
                <div class="mask_title">
                    <div class="xian"></div>
                    <div class="title">充值</div>
                </div>
                <div @click="handleRefund" class="quxiao">×</div>
            </div>
            <div class="xian2"></div>
            <div class="moneylist">
                <div :class="['moneys', money == item.setmoney ? 'money' : '']" v-for="item in moneylist" :key="item.id"
                    @click="money = item.setmoney">
                    <div class="font1">{{ item.setmoney }}</div>
                    <div v-if="item.givemoney != null && item.givemoney != '' && item.givemoney != 0" class="font2">送{{
                        item.givemoney }}</div>
                </div>
            </div>
            <div class="footer" @click="handleSubmit">确认充值</div>
        </div>
    </div>
</template>
    
<script>
export default {
    name: "recharge",
    props: ['data'],
    data() {
        return {
            moneylist: [],
            money: null,
        };
    },
    mounted() {
        this.accountSetmoneylist()
    },
    methods: {
        //取消弹窗
        handleRefund() {
            let data = {
                flag: false,//弹窗是否显示
                submitFlag: false//是否调用接口
            }
            this.$emit("rechargeCall", data);
        },
        handleSubmit() {
            let data = {
                flag: false,//弹窗是否显示
                submitFlag: true,//是否调用接口
                money: this.money
            }
            this.$emit("rechargeCall", data);
        },
        // 得到系统设置的充值金额
        async accountSetmoneylist() {
            let res = await this.$api.accountSetmoneylist();
            if (res.code == 1) {
                this.moneylist = res.data;
                this.moneylist.forEach(item => {
                    item.setmoney = Number(item.setmoney).toFixed(0)
                    item.givemoney = Number(item.givemoney).toFixed(0)

                })
            }
        }
    },
};
</script>
    
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask {

    .mask-main {
        width: 45.365vw;
        border-radius: 0.729vw 0.521vw 0.521vw 0.521vw;
        padding: 2.315vh 1.823vw 0.741vh;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .mask-cen {
            width: 41.563vw;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .mask_title {
                display: flex;
                align-items: center;

                .xian {
                    width: 0.26vw;
                    height: 0.885vw;
                    background-color: #2c72ff;
                    margin-right: 0.469vw;
                }

                .title {
                    font-family: MicrosoftYaHei-Bold;
                    font-size: 0.833vw;
                    color: #333333;
                }
            }

            .quxiao {
                font-family: MicrosoftYaHeiLight;
                font-size: 1.875vw;
                color: #333333;
                cursor: pointer;
            }

        }

        .xian2 {
            width: 41.719vw;
            height: 0.156vw;
            background-color: #ececec;
            margin-top: 2.037vh;
        }

        .moneylist {
            width: 41.719vw;
            margin-top: 0.74vh;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            .moneys {
                width: 9.948vw;
                height: 9.352vh;
                background-color: #ffffff;
                border: solid 0.052vw #e6e6e6;
                margin-top: 1.019vh;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: center;
                margin-right: 0.625vw;
                cursor: pointer;

                .font1 {
                    font-family: MicrosoftYaHei;
                    font-size: 2.13vh;
                    color: #333333;
                }

                .font2 {
                    font-family: MicrosoftYaHei;
                    font-size: 1.481vh;
                    color: #333333;
                    margin-top: 1.481vh;
                }
            }

            .money {
                background-color: #2c72ff;
                border: solid 0.104vw #0055ff;

                .font1 {
                    color: #ffffff;
                }

                .font2 {
                    color: #fff000;
                }
            }
        }

        .moneylist>div:nth-child(4n) {
            margin-right: 0;
        }

        .footer {
            width: 6.25vw;
            height: 4.259vh;
            line-height: 4.259vh;
            text-align: center;
            background-color: #2c72ff;
            border-radius: 0.521vw;
            font-family: MicrosoftYaHei;
            font-size: 0.885vw;
            color: #ffffff;
            margin-top: 4.074vh;
            margin-bottom: 2.963vh;
            cursor: pointer;
        }
    }

}
</style>