<template>
    <div class="order-mask" v-if="data.flag" @click.stop="handleRefund">
        <!-- 添加订单 -->
        <div class="mask-main">
            <!--页面内容-->
            <img :src="imgurl + data.url" alt="" @click="CopyImg(data.url)" />
        </div>
    </div>
</template>

<script>
export default {
    name: "MaskPack",
    props: ['data'],
    data() {
        return {
        };
    },
    mounted() {
        // this.copycode(request.uploadsuRL("/uploads/img/1701755165676283.jpg"));
    },
    methods: {
        /* 绘制图片 */
        async CopyImg(url) {
            if (url == null || url == '') {
                return false
            }
            // const canvas = document.createElement('canvas');
            // const image = new Image();
            // image.setAttribute('crossOrigin', 'Anonymous'); // 可能会有跨越问题
            // image.src = url;
            // console.log(image.src)
            // image.onload = () => {
            //   canvas.width = image.width;
            //   canvas.height = image.height;
            //   canvas.getContext('2d').drawImage(image, 0, 0); // 加载img到画板
            //   const url = canvas.toDataURL('image/png'); // 转换图片为dataURL，格式为png
            //   console.log(url)
            //   this.clipboardImg(url); // 调用复制方法
            // };
            let res = await this.$api.indexSetimage({
                image: this.imgurl + url
            })
            if (res.code == 1) {
                this.clipboardImg(res.data)
            }
        },
        /* 复制图片 */
        async clipboardImg(url) {
            try {
                const data = await fetch(url);
                const blob = await data.blob();
                await navigator.clipboard.write([
                    new window.ClipboardItem({
                        [blob.type]: blob
                    })
                ]);
                this.$message({
                    type: 'success',
                    message: '复制成功'
                });
            } catch (err) {
                this.$message({
                    type: 'warning',
                    message: '复制失败'
                });
            }
        },


        //取消弹窗
        handleRefund() {
            let data = {
                flag: false,
                submitFlag: false,
            }
            this.$emit("clothseleCall", data)
        }
    }
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask {
    height: auto;

    .mask-main {
        width: 26.042vw;
        height: auto;
        border-radius: 1.296vh 0.521vw 0.926vh 0.521vw;
        padding: 0;

        img {
            width: 100%;
            height: auto;
        }
    }
}
</style>