<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 状态充值 -->
        <div class="mask-main">
            <!-- 充值中 -->
            <div v-if="type == 1" class="mask-cen1">
                <img src="@/assets/images/icon/recharging.png" alt="">
                <div class="font1">充值中......</div>
            </div>
            <!-- 充值成功 -->
            <div v-if="type == 2" class="mask-cen2">
                <img src="@/assets/images/icon/success.png" alt="">
                <div class="font2">充值成功！</div>
                <div class="font3" @click="handleRefund">确定</div>
            </div>
            <!-- 充值失败 -->
            <div v-if="type == 3" class="mask-cen2">
                <img src="@/assets/images/icon/fail.png" alt="">
                <div class="font2">充值失败！</div>
                <div class="font3" @click="handleSubmit">重新充值</div>
            </div>
        </div>
    </div>
</template>
    
<script>
export default {
    name: "confirmrecharge",
    props: ['data'],
    data() {
        return {
            type: ''
        };
    },
    mounted() {

    },
    methods: {
        //取消弹窗
        handleRefund() {
            let data = {
                flag: false,//弹窗是否显示
                submitFlag: false//是否调用接口
            }
            this.$emit("confirmrechargeCall", data);
        },
        handleSubmit() {
            let data = {
                flag: false,//弹窗是否显示
                submitFlag: true,//是否调用接口

            }
            this.$emit("confirmrechargeCall", data);
        },

    },
    watch: {
        data: {
            handler(newVal, oldVal) {
                //  console.log(newVal);
                this.type = newVal.type;
            },
            deep: true
        }
    }
};
</script>
    
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask {

    .mask-main {
        width: auto;
        background-color: #ffffff;
        border-radius: 0.729vw 0.521vw 0.521vw 0.521vw;
        padding: 1.667vw 4.115vw 1.615vw 4.115vw;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .mask-cen1 {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            img {
                width: auto;
                height: 1.875vw;
                margin-right: 0.573vw;
            }

            .font1 {
                font-family: MicrosoftYaHei;
                font-size: 1.198vw;
                color: #333333;
            }
        }

        .mask-cen2 {
            width: 17.813vw;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;

            img {
                width: auto;
                height: 6.823vw;
            }

            .font2 {
                font-family: MicrosoftYaHei;
                font-size: 1.198vw;
                color: #333333;
                margin-top: 1.51vw;
            }

            .font3 {
                width: 6.25vw;
                height: 2.396vw;
                line-height: 2.396vw;
                text-align: center;
                font-family: MicrosoftYaHei;
                font-size: 0.885vw;
                color: #ffffff;
                background-color: #2c72ff;
                border-radius: 0.521vw;
                margin-top: 4.271vw;
                margin-bottom: 1.66666vw;
                cursor: pointer;
            }
        }
    }

}
</style>