<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 购买成功 失败 -->
        <div class="mask-main">
            <div class="mask-cen">
                <img v-if="status" src="@/assets/images/icon/success.png" alt="">
                <img v-if="!status" src="@/assets/images/icon/fail.png" alt="">
                <div v-if="status" class="paytip">购买成功！</div>
                <div v-if="!status" class="paytip">购买失败！</div>
                <div v-if="status" class="endtime">倒计时{{ countdown }}秒</div>
                <div v-if="!status" class="buyagain" @click="handleSubmit">重新购买</div>

                <div class="cancellation" @click="handleRefund">×</div>
            </div>
        </div>
    </div>
</template>
    
<script>
export default {
    name: "paytip",
    props: ['data'],
    data() {
        return {
            status: null,//true 成功 false 失败
            countdown: 3,
        };
    },
    mounted() {
    },
    methods: {
        // 定时器
        countdownTimer() {
            let timer = setInterval(() => {
                this.countdown--;
                if (this.countdown <= 0) {
                    //  console.log(this.countdown);
                    this.handleRefund()
                    clearInterval(timer)
                }
            }, 1000)
        },
        //取消弹窗
        handleRefund() {
            let data = {
                flag: false,//弹窗是否显示
                submitFlag: false//是否调用接口
            }
            this.$emit("paytipCall", data);
        },
        handleSubmit() {
            let data = {
                flag: false,//弹窗是否显示
                submitFlag: true//是否调用接口
            }
            this.$emit("paytipCall", data);
        },
    },
    watch: {
        data: {
            deep: true,
            handler(newVal, oldVal) {
                this.status = newVal.status
                if (this.status) {
                    this.countdown = 3
                    this.countdownTimer()
                }
            }
        }
    }
};
</script>
    
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask {
    .mask-main {
        width: 31.25vw;
        padding: 0;
        border-radius: 0.729vw 0.521vw 0.521vw 0.521vw;

        .mask-cen {
            margin: 2.397vh 1.563vw 3.471vh;
            width: 28.125vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            img {
                width: 7.813vw;
                height: 7.813vw;
                background-color: #2c72ff;
                margin-top: 2.562vh;
                border-radius: 50%;
            }

            .paytip {
                font-family: SourceHanSansCN-Medium;
                font-size: 2.479vh;
                color: #333333;
                margin-top: 2.81vh;
            }

            .endtime {
                font-family: MicrosoftYaHei;
                font-size: 0.938vw;
                color: #2c72ff;
                margin-top: 3.636vh;
                margin-bottom: 1.983vh;
            }

            .buyagain {
                width: 9.896vw;
                height: 3.802vh;
                line-height: 3.802vh;
                text-align: center;
                background-color: #2c72ff;
                border-radius: 0.521vw;
                font-family: MicrosoftYaHei;
                font-size: 0.885vw;
                color: #ffffff;
                margin-top: 3.636vh;
                margin-bottom: 1.983vh;
            }

            .cancellation {
                font-family: MicrosoftYaHeiLight;
                font-size: 1.875vw;
                color: #333333;
                cursor: pointer;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

}
</style>