<template>
    <div class="order-mask" v-if="dataVal.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    {{ dataVal.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <table>
                    <tr>
                        <td class="table-td xin2">登录账号</td>
                        <td colspan="3">
                            <input type="text" v-model="users.username" placeholder="限4-16个字母和数字，必须以字母开头，不区分大小写">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">真实姓名</td>
                        <td colspan="3">
                            <input type="text" v-model="users.realname" placeholder="请输入真实姓名">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">手机号</td>
                        <td colspan="3">
                            <input type="text" v-model="users.phone" placeholder="请输入手机号">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">登录密码</td>
                        <td colspan="3">
                            <input type="text" v-model="users.password"
                                :placeholder="dataVal.type == 'edit' ? '默认为空，输入密码为重置密码' : '限6-16个字母和数字'">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">确定密码</td>
                        <td colspan="3">
                            <input type="text" v-model="reppassword" placeholder="请确认密码">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">角色</td>
                        <td colspan="3">
                            <el-select :popper-append-to-body="false" v-model="users.role_id" placeholder="请选择角色"
                                class="selec" clearable>
                                <el-option v-for="item in role_type" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-td xin2">是否启用</td>
                        <td colspan="3">
                            <div style="padding-left: 1.25vw;">
                                <el-switch v-model="users.status" active-color="#13ce66" inactive-color="#D7D6D6">
                                </el-switch>
                            </div>
                        </td>
                    </tr>
                </table>
                <div v-if="dataVal.btn != ''" class="save" @click="handleSubmit">{{ dataVal.btn }}</div>
            </div>
            <div class="qrcode" v-if="dataVal.image">
                <img style="width: auto;height: 12.76vw;" :src="imgurl + dataVal.image" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AddAccount",
    props: ["dataVal"],
    data() {
        return {
            radio: 3,
            reppassword: "",
            users: {
                username: "",
                realname: "",
                password: "",
                role_id: "",
                phone: "",
                status: true,
            },
            role_type: [],
            image: ""
        };
    },
    mounted() {

    },
    methods: {
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                submitFlag: false,//是否调用接口
            }
            this.$emit("addAccountCell", obj);
        },
        handleSubmit() {
            this.users.status = this.users.status == false ? 0 : 1

            this.role_type.forEach(element => {
                if (element.name == this.users.role_id) {
                    this.users.role_id = element.id
                }
            })
            let obj = {
                flag: false,
                submitFlag: true,//是否调用接口
                users: {
                    username: this.users.username,
                    realname: this.users.realname,
                    password: this.users.password,
                    role_id: this.users.role_id,
                    phone: this.users.phone,
                    status: this.users.status,
                }
            }
            if (this.users.id) {
                obj.users.id = this.users.id
            }
            if (this.users.password == this.reppassword) {
                this.$emit("addAccountCell", obj);
            } else {
                this.$message({
                    type: "error",
                    message: "请确认确认密码是否与密码相同"
                })
            }
            this.users.status = this.users.status == 0 ? false : true
        },
        async adminGetrole() {
            let res = await this.$api.adminGetrole()
            if (res.code == 1) {
                this.role_type = res.data
            }

        },

    },
    created() {
        this.adminGetrole()
    },
    watch: {
        dataVal: {
            handler(newVal, oldVal) {
                // console.log(newVal, '这样也可以得到数据~~~');
                if (newVal.type == 'edit') {
                    this.users.name = newVal.value;
                    this.role_type.forEach(element => {
                        if (element.id == this.role_id) {
                            this.role_id = this.role_type.name;
                        }
                    })
                    newVal.item.password = "";
                    this.users = { ...newVal.item, id: newVal.id }
                    this.reppassword = ""
                } else if (newVal.type == 'add' && newVal.image) {

                } else {
                    this.reppassword = "",
                        this.users = {
                            ...{
                                username: "",
                                realname: "",
                                password: "",
                                role_id: "",
                                phone: "",
                                status: true,
                            }
                        }
                }
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask .mask-main {
    width: 32.969vw;
}

.table table {
    width: 32.969vw;
    margin-top: 1.852vh;
}

.table table input {
    padding-left: 1.25vw;
    font-size: 0.833vw;
}

/deep/ .el-input--suffix .el-input__inner {
    padding-left: 1.25vw;
}

.qrcode {
    max-width: 12.76vw;
    max-height: 12.76vw;
    margin: 4.167vh auto 0;
}

.selec {
    /deep/.el-select-dropdown {
        top: 62.852vh !important;
        left: 41.865vw !important;
        border: 0.052vw solid #E4E7ED;
        border-radius: 0.208vw;
        margin: 0.463vh 0;

        .el-select-dropdown__list {
            padding: 0.556vh 0;
        }

        .el-popper .popper__arrow::after {
            top: 0.093vh !important;
        }
    }
}
</style>