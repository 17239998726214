<template>
    <!-- 重拍 -->
    <div v-if="data.flag" class="mask">
        <div class="mask-main">
            <div class="mask-header">
                <div class="mask-left">
                    {{ data.title }}
                </div>
                <i @click="handleClose" class="el-icon-close posi"></i>
            </div>
            <div class="mask-line"></div>
            <div class="mask-table">
                <div class="mask-lang">服装列表</div>
                <el-table class="center_table" max-height="500" ref="multipleTable" :data="importArr" tooltip-effect="dark"
                    style="width: 100%" border :header-cell-style="{
                        width: 72.9699 + 'vw',
                        height: 5.185 + 'vh',
                        background: '#F9F9F9',
                        fontSize: 0.833 + 'vw',
                        textAlign: 'center',

                    }">
                    <el-table-column align="center" prop="bianhao" label="物资ID" min-width="18.6px">
                        <template slot-scope="scope">
                            <span class="aFu_span" @click="handlerTo(scope.row.id)">{{ scope.row.bianhao }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" align="center" label="物资名称" show-overflow-tooltip
                        min-width="16.7px"></el-table-column>
                    <el-table-column align="center" prop="locator_id" label="库位编号" show-overflow-tooltip
                        min-width="12.5px"></el-table-column>
                    <el-table-column align="center" prop="counts" label="数量" show-overflow-tooltip
                        min-width="9.7px"></el-table-column>
                    <el-table-column align="center" prop="cp" label="物资实物" show-overflow-tooltip min-width="23.7px">
                        <template slot-scope="scope">
                            <div class="aFu" @contextmenu="disableRightClick">
                                <el-image
                                    style="max-width: 4.167vw !important;max-height: 3.704vh!important;width: auto !important;height: auto!important;"
                                    :src="imgurl + scope.row.images" @click="bigimgclick(imgurl + scope.row.images)">
                                </el-image>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="cate" label="物资分类" show-overflow-tooltip
                        min-width="8.8px"></el-table-column>
                    <el-table-column align="center" prop="sex" label="适用性别" show-overflow-tooltip
                        min-width="8.9px"></el-table-column>
                    <el-table-column align="center" prop="createtime" label="创建时间" show-overflow-tooltip
                        min-width="12.0px"></el-table-column>
                    <el-table-column align="center" prop="adminname" label="创建人" show-overflow-tooltip
                        min-width="15.8px"></el-table-column>
                </el-table>
            </div>
        </div>
        <!-- 预览 -->
        <ImgPreview :data="imgpreviewObj" @imgpreviewCall="imgpreviewCall"></ImgPreview>
    </div>
</template>

<script>
//上传底片
import FailReason from '@/components/management/FailReason.vue';
export default {
    name: "substancesCell",
    props: ['data'],
    data() {
        return {
            importArr: [],

            shopname: '',
            imgpreviewObj: {
                flag: false,
                src: '',
                shopname: ''
            }
        }
    },
    mounted() {
        this.indexGetinfo()
    }
    ,
    methods: {
        //得到当前账号所关联的店铺信息
        async indexGetinfo() {
            let res = await this.$api.indexGetinfo();
            if (res.code == 1) {
                //  console.log(res.data);
                this.shopname = res.data.shopname
            }
        },
        // 阻止默认右键菜单的显示
        disableRightClick(event) {
            event.preventDefault();
            return false
        },
        bigimgclick(url) {
            this.imgpreviewObj.flag = true
            this.imgpreviewObj.src = url
            this.imgpreviewObj.shopname = this.shopname
        },
        imgpreviewCall() {
            this.imgpreviewObj.flag = false
        },
        //关闭弹窗
        handleClose() {
            let obj = {
                flag: false
            }
            // console.log(obj);
            this.$emit('substancesCell', obj)
        },
        handlerTo(id) {
            this.$router.push({ path: "/materialDetail", query: { id: id } })
        }

    },
    watch: {
        data: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal.obj.length > 0) {
                    newVal.obj.forEach(element => {
                        element.images = element.images.split(',')[0] + ""
                    })
                    this.importArr = newVal.obj
                } else {
                    this.importArr = newVal.obj
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import url("@/assets/css/maskCap.less");
</style>