<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 立即购买 微信支付宝支付 -->
        <div class="mask-main">
            <div class="mask-cen">
                <div class="mask_head">
                    <div class="quxiao">
                        <div class="bad" @click="handleRefund">×</div>
                    </div>
                    <div class="price">
                        <em class="font1">¥ </em>{{ firstamount }}.<em class="font2">{{ endamount }}</em>
                    </div>
                </div>
                <div class="mask_footer">
                    <div class="weixi">
                        <img :src="imgurl" class="qrcode" alt="">
                        <img src="@/assets/images/icon/weixin.png" alt="">
                    </div>
                    <div class="weixi">
                        <img :src="imgurl" class="qrcode" alt="">
                        <img src="@/assets/images/icon/alipay.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
export default {
    name: "pay2",
    props: ['data'],
    data() {
        return {
            price: '',
            firstamount: '',
            endamount: ''
        };
    },
    mounted() {
    },
    methods: {
        //取消弹窗
        handleRefund() {
            let data = {
                flag: false,//弹窗是否显示
                submitFlag: false//是否调用接口
            }
            this.$emit("paysCall", data);
        },
        handleSubmit() {
            let data = {
                flag: false,//弹窗是否显示
                submitFlag: true//是否调用接口
            }
            this.$emit("paysCall", data);
        },
    },
    watch: {
        data: {
            handler(newVal, oldVal) {
                this.price = newVal.money
                if (newVal.money % 1 === 0) {
                    this.firstamount = newVal.money.indexOf('.') == -1 ? newVal.money : newVal.money.slice(0, newVal.money.indexOf("."))
                    this.endamount = '00'
                } else {
                    this.firstamount = newVal.money.slice(0, newVal.money.indexOf("."))
                    this.endamount = newVal.money.slice(newVal.money.indexOf(".") + 1, newVal.money.length)
                }
            },
            deep: true
        }
    }
};
</script>
    
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask {
    .mask-main {
        width: 36.458vw;
        border-radius: 0.729vw 0.521vw 0.521vw 0.521vw;
        padding: 0;

        .mask-cen {
            margin: 1.51vw 1.563vw 3.594vh;
            width: 33.333vw;
            display: flex;
            flex-direction: column;
            align-items: center;

            .mask_head {
                width: 33.333vw;
                height: 6.615vw;
                display: flex;
                flex-direction: column;
                align-items: center;

                .quxiao {
                    width: 33.333vw;
                    height: 2.188vw;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;

                    .bad {
                        font-family: MicrosoftYaHeiLight;
                        font-size: 1.875vw;
                        color: #333333;
                        cursor: pointer;
                    }
                }

                .price {
                    // width: 9.688vw;
                    height: 2.813vw;
                    font-family: SourceHanSansCN-Medium;
                    font-size: 3.646vw;
                    color: #ff0000;

                    .font1 {
                        font-size: 2.292vw;
                        font-style: normal;
                    }

                    .font2 {
                        font-size: 2.396vw;
                        font-style: normal;
                    }
                }
            }

            .mask_footer {
                width: 28.646vw;
                height: 13.542vw;
                margin-top: 3.906vw;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;

                .weixi {
                    width: 12.5vw;
                    height: 13.542vw;
                    background-color: #ffffff;
                    border-radius: 0.521vw;
                    border: solid 0.052vw #52c41a;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;

                    img {
                        width: auto;
                        height: 1.094vw;
                    }

                    .qrcode {
                        width: 10.938vw;
                        height: 10.938vw;
                    }
                }
            }
        }
    }

}
</style>