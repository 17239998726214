<template>
    <div class="order-mask" v-if="dataVal.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">浏览记录</div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="analysislist">
                <div class="analysis analysis1">
                    <div class="conclusion_t">结论：</div>
                    <div class="conclusion">
                        <div>衣码：</div>
                        <div class="sizeconclusion" v-for="(item, index) in sizeconclusion" :key="item.name">
                            <div>{{ item.name }}</div>
                            <div v-if="index < sizeconclusion.length - 1">{{
        sizeconclusion[index].count == sizeconclusion[index + 1].count ? '=' : '>' }}</div>
                        </div>
                    </div>
                    <div class="conclusion">
                        <div>性别：</div>
                        <div class="sizeconclusion" v-for="(item, index) in sexconclusion" :key="item.name">
                            <div>{{ item.name }}</div>
                            <div v-if="index < sexconclusion.length - 1">{{
        sexconclusion[index].count == sexconclusion[index + 1].count ? '=' : '>' }}</div>
                        </div>
                    </div>
                    <div class="conclusion">
                        <div>主题：</div>
                        <div class="sizeconclusion" v-for="(item, index) in themeconclusion" :key="item.name">
                            <div>{{ item.name }}</div>
                            <div v-if="index < themeconclusion.length - 1">{{
        themeconclusion[index].count == themeconclusion[index + 1].count ? '=' : '>' }}</div>
                        </div>
                    </div>
                </div>
                <div class="analysis analysis2">
                    <div class="analysis_t">客户浏览衣码分析</div>
                    <div class="analysis_nr" id="analysis_nr1">
                        <div class="size" v-for="item in tableData.sizeaddup1" :key="item">{{ item }}</div>
                    </div>
                </div>
                <div class="analysis analysis3">
                    <div class="analysis_t">客户浏览性别分析</div>
                    <div class="analysis_nr" id="analysis_nr2"></div>
                </div>
                <div class="analysis analysis4">
                    <div class="analysis_t">客户浏览主题分析</div>
                    <div class="analysis_nr" id="analysis_nr3">
                        <div class="theme" v-for="item in tableData.themeaddup1" :key="item">{{ item }}</div>
                    </div>
                </div>
            </div>
            <div class="cont_actable">
                <div class="cont_act_l">
                    <div :class="['cont_act_t', activeName == item.id ? 'cont_act' : '']" v-for="item in activeList"
                        :key="item.id" @click="handleTab(item.id)">
                        <div :class="['xian', activeName == item.id ? 'xian2' : '']"></div>
                        <div :class="['cont_t', activeName == item.id ? 'cont_t_act' : '']">{{ item.name }}({{
        item.count
    }})</div>
                        <div class="xian"></div>
                    </div>
                </div>
                <div class="cont_table_r">
                    <div class="table">
                        <el-table class="center_table" ref="multipleTable" :data="tableData.list" tooltip-effect="dark"
                            height="22.208vw" style="width: 100%" border :header-cell-style="{
        width: 81.823 + 'vw',
        height: 5.185 + 'vh',
        background: '#F9F9F9',
        fontSize: 0.729 + 'vw',
        textAlign: 'center',
    }" @selection-change="handleSelectionChange">
                            <el-table-column align="center" prop="id" type="index" label="序号" min-width="7.2px">
                            </el-table-column>
                            <el-table-column align="center" prop="bianhao" label="样片ID" min-width="14.2px">
                                <!-- <template slot-scope="scope">
                                    <span class="aFu_span">{{ scope.row.bianhao }}</span>
                                </template> -->
                            </el-table-column>
                            <el-table-column align="center" prop="image" label="客户可见标准片" min-width="16.3px">
                                <template slot-scope="scope">
                                    <div class="aFu" @contextmenu="disableRightClick">
                                        <el-image :src="imgurl + scope.row.image"
                                            @click="bigimgclick(imgurl + scope.row.image)">
                                        </el-image>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" prop="theme" label="主题" min-width="12.2px">
                            </el-table-column>
                            <el-table-column align="center" prop="sex" label="适用性别" min-width="12.3px">
                            </el-table-column>
                            <el-table-column align="center" prop="size" label="衣码" min-width="12.2px">
                            </el-table-column>
                            <el-table-column align="center" prop="type" label="浏览类型" min-width="15.7px">
                            </el-table-column>
                            <el-table-column align="center" prop="createtime" label="浏览时间" min-width="23px">
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- 底部分页 -->
                    <div class="footer-page">
                        <span>共{{ tableData.total_count }}条</span>
                        <el-pagination popper-class="paging" @size-change="handleSizeChange"
                            @current-change="handleCurrentChange" background :current-page="page"
                            :page-sizes="[10, 20, 30, 40]" :page-size="count" layout="sizes, prev, pager, next, jumper"
                            :total="tableData.total_count" :pager-count="5">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- 预览 -->
        <ImgPreview :data="imgpreviewObj" @imgpreviewCall="imgpreviewCall"></ImgPreview>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: "ViewTrackrecord",
    props: ["dataVal"],
    data() {
        return {
            page: 1,
            count: 10,
            //表格切换
            activeName: 0,
            activeList: [
                {
                    id: 0,
                    name: "全部",
                    count: 0
                },
                {
                    id: 7,
                    name: "停留3秒以下",
                    count: 0
                },
                {
                    id: 8,
                    name: "停留3-14秒",
                    count: 0
                },
                {
                    id: 1,
                    name: "停留15-30秒",
                    count: 0
                },
                {
                    id: 2,
                    name: "停留31-60秒",
                    count: 0
                },
                {
                    id: 3,
                    name: "停留1-3分钟",
                    count: 0
                },
                {
                    id: 4,
                    name: "停留3分钟以上",
                    count: 0
                },
                {
                    id: 5,
                    name: "喜欢",
                    count: 0
                },
                {
                    id: 6,
                    name: "收藏",
                    count: 0
                },
            ],
            imgpreviewObj: {
                flag: false,
                src: '',
                shopname: ''
            },
            shopname: '',
            sizethemecolor: ['#1fb243', '#901a76', '#0f5df9', '#8b89ff', '#7a0961', '#e69c04', '#ffac00',
                '#f484db', '#4541f1', '#ffac00', '#ffe400', '#d436b1', '#e104ca', '#999999'],
            sizeconclusion: [], //衣码结论
            themeconclusion: [], //主题结论
            sexconclusion: [], //性别结论
            tableData: {
                list: [],
                total_count: 0
            },
            multipleTable: [],
        };
    },
    mounted() {
        this.indexGetinfo()

        // // 随机颜色
        // var getRandomColor = function () {
        //     var r = Math.round(Math.random() * 255), g = Math.round(Math.random() * 255), b = Math.round(Math.random() * 255);
        //     var color = r << 16 | g << 8 | b;
        //     return "#" + color.toString(16)
        // }
        // console.log(getRandomColor());
    },
    created() {

    },
    methods: {
        //得到当前账号所关联的店铺信息
        async indexGetinfo() {
            let res = await this.$api.indexGetinfo();
            if (res.code == 1) {
                this.shopname = res.data.shopname
            }
        },
        // 阻止默认右键菜单的显示
        disableRightClick(event) {
            event.preventDefault();
            return false
        },
        bigimgclick(url) {
            this.imgpreviewObj.flag = true
            this.imgpreviewObj.src = url
            this.imgpreviewObj.shopname = this.shopname
        },
        imgpreviewCall() {
            this.imgpreviewObj.flag = false
        },
        //tab切换
        handleTab(id) {
            this.activeName = id;
            this.spreadViewlog()
        },
        //分页
        handleSizeChange(val) {
            this.count = val
            this.spreadViewlog()
        },
        handleCurrentChange(val) {
            this.page = val
            this.spreadViewlog()
        },
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                typeText: ""
            }
            this.$emit("trackrecordCell", obj);
        },
        handleSubmit() {
            this.handleRefund();
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        async spreadViewlog() {
            let res = await this.$api.spreadViewlog({
                id: this.id,
                page: this.page,
                count: this.count,
                type: this.activeName
            })
            if (res.code == 1) {
                this.tableData = res.data
                // 列表
                this.tableData.list.forEach(item => {
                    item.createtime = this.$FilDate.filDate(item.createtime * 1000)
                    this.activeList.forEach(element => {
                        if (element.id == item.type) {
                            item.type = element.name
                        }
                    })
                })
                this.activeList.forEach(element => {
                    element.count = 0
                })
                this.tableData.leftaddup.forEach(item => {
                    this.activeList.forEach(element => {
                        if (element.id == item.type) {
                            element.count = item.counts
                        }
                    })
                    this.activeList[0].count += item.counts
                })

                // 结论
                let sizeconclusion = []
                let themeconclusion = []
                let sexconclusion = []
                let sizeaddup = JSON.parse(JSON.stringify(this.tableData.sizeaddup2))
                let themeaddup = JSON.parse(JSON.stringify(this.tableData.themeaddup2))
                let sexaddup = JSON.parse(JSON.stringify(this.tableData.sexaddup))
                sizeaddup.forEach((item, index) => {
                    sizeconclusion.push({})
                    sizeconclusion[index].count = item
                    sizeconclusion[index].index = index
                    sizeconclusion[index].name = this.tableData.sizeaddup1[index]
                })

                for (let i = 0; i < sizeconclusion.length; i++) {
                    for (let j = i; j < sizeconclusion.length; j++) {
                        if (sizeconclusion[i].count < sizeconclusion[j].count) {
                            let temp = sizeconclusion[i]
                            sizeconclusion[i] = sizeconclusion[j]
                            sizeconclusion[j] = temp
                        }
                    }
                }

                themeaddup.forEach((item, index) => {
                    themeconclusion.push({})
                    themeconclusion[index].count = item
                    themeconclusion[index].index = index
                    themeconclusion[index].name = this.tableData.themeaddup1[index]
                })

                for (let i = 0; i < themeconclusion.length; i++) {
                    for (let j = i; j < themeconclusion.length; j++) {
                        if (themeconclusion[i].count < themeconclusion[j].count) {
                            let temp = themeconclusion[i]
                            themeconclusion[i] = themeconclusion[j]
                            themeconclusion[j] = temp
                        }
                    }
                }

                let sexlist = ['通用', '男', '女']
                sexaddup.forEach((item, index) => {
                    sexconclusion.push({})
                    sexconclusion[index].count = item
                    sexconclusion[index].index = index
                    sexconclusion[index].name = sexlist[index]
                })

                for (let i = 0; i < sexconclusion.length; i++) {
                    for (let j = i; j < sexconclusion.length; j++) {
                        if (sexconclusion[i].count < sexconclusion[j].count) {
                            let temp = sexconclusion[i]
                            sexconclusion[i] = sexconclusion[j]
                            sexconclusion[j] = temp
                        }
                    }
                }

                this.sexconclusion = sexconclusion

                if (sizeconclusion.length > 3) {
                    this.sizeconclusion = [sizeconclusion[0], sizeconclusion[1], sizeconclusion[2]]
                } else {
                    this.sizeconclusion = sizeconclusion
                }
                if (themeconclusion.length > 3) {
                    this.themeconclusion = [themeconclusion[0], themeconclusion[1], themeconclusion[2]]
                } else {
                    this.themeconclusion = themeconclusion
                }

                // 饼图
                let obj = [
                    { value: '', name: '通用' },
                    { value: '', name: '男' },
                    { value: '', name: '女' }
                ]
                res.data.sexaddup.forEach((item, index) => {
                    obj[index].value = item
                })
                this.shanxing(obj)

                // 衣码主题分析
                this.$nextTick(() => {
                    let maxsize = 0;
                    for (let i = 0; i < this.tableData.sizeaddup2.length; i++) {
                        if (maxsize < this.tableData.sizeaddup2[i]) {
                            maxsize = this.tableData.sizeaddup2[i]
                        }
                    }
                    this.tableData.sizeaddup2.forEach((item, index) => {
                        document.querySelectorAll('.size')[index].style.fontSize = (10 + item) * 0.0625 + 'vw'
                        if (index >= 0 && index < 14) {
                            document.querySelectorAll('.size')[index].style.color = this.sizethemecolor[index]
                        } else if (index >= 14 && index < 28) {
                            document.querySelectorAll('.size')[index - 14].style.color = this.sizethemecolor[index - 14]
                        }
                    })

                    let maxtheme = 0;
                    for (let i = 0; i < this.tableData.themeaddup2.length; i++) {
                        if (maxtheme < this.tableData.themeaddup2[i]) {
                            maxtheme = this.tableData.themeaddup2[i]
                        }
                    }
                    this.tableData.themeaddup2.forEach((item, index) => {
                        document.querySelectorAll('.theme')[index].style.fontSize = (10 + item) * 0.0625 + 'vw'
                        if (index >= 0 && index < 14) {
                            document.querySelectorAll('.theme')[index].style.color = this.sizethemecolor[index]
                        } else if (index >= 14 && index < 28) {
                            document.querySelectorAll('.theme')[index - 14].style.color = this.sizethemecolor[index - 14]
                        }
                    })
                })
            }
        },
        shanxing(seriesdata) {
            this.$nextTick(() => {
                var chartDom = document.getElementById('analysis_nr2');
                var myChart = echarts.init(chartDom);

                var option;

                option = {
                    tooltip: {
                        trigger: 'item',
                        formatter(params) {
                            // console.log(params); // 打印数据
                            let ele = ``;
                            ele += `<div style="font-size:0.8333vw;margin-bottom:0.2604vw;">${params.data.name}</div>`;
                            ele += `<div style="display:flex;align-items: center;height:1.5625vw;font-size:0.625vw;">数量：
                        <div style="font-size:0.7292vw;color:${params.color};margin:0 0 0 0.5208vw;">${params.data.value
                                }</div></div>`;
                            ele += `<div style="display:flex;align-items: center;height:1.5625vw;font-size:0.625vw;">占比：
                        <div style="font-size:0.7292vw;color:${params.color};margin:0 0 0 0.5208vw;">${params.percent
                                }%</div></div>`;
                            return ele;
                        }
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left',
                        top: 'bottom',
                        width: '10%',
                        itemWidth: 8,
                        itemHeight: 8,
                        textStyle: {
                            fontSize: 12,
                        },
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: '50%',
                            left: '3%',
                            // data: [
                            //     { value: 1048, name: '男' },
                            //     { value: 735, name: '女' },
                            //     { value: 580, name: '通用' }
                            // ],
                            data: seriesdata,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                                }
                            },
                            label: {
                                fontSize: 12,
                            },
                            labelLine: {
                                length: 5,
                                length2: 5,
                            },
                            itemStyle: {
                                color: function (colors) {
                                    var colorList = ['#44c44e', '#901a76', '#ffac00']
                                    return colorList[colors.dataIndex];
                                }
                            }
                        }
                    ]
                };

                option && myChart.setOption(option);
            })
        },
    },
    watch: {
        dataVal: {
            deep: true,
            handler(newvalue, oldvalue) {
                if (newvalue.flag) {
                    this.activeName = 0
                    this.id = newvalue.id;
                    this.spreadViewlog()
                }
            }
        }
    }
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/mask.less");
@import url("@/assets/css/table.less");

.order-mask .mask-main {
    width: 69.688vw;
    box-sizing: border-box;
    border-radius: .7292vw;
    padding: 0;

    .mask-nav {
        height: 3.6458vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1.5625vw;
        background-color: #0f5df9;
        border-radius: .7292vw .7292vw 0 0;
        border: 0;

        .mask-title {
            font-family: MicrosoftYaHei-Bold;
            font-size: .8854vw;
            letter-spacing: .0469vw;
            color: #ffffff;
        }

        i {
            font-family: MicrosoftYaHeiLight;
            font-size: 1.25vw;
            color: #ffffff;
        }
    }

    .analysislist {
        width: 100%;
        padding: 1.0417vw;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .analysis {
            width: 15.625vw;
            height: 9.375vw;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-start;

            .analysis_t {
                font-family: MicrosoftYaHei;
                font-size: 0.729vw;
                letter-spacing: 0.036vw;
                color: #333333;
            }
        }

        .analysis:nth-child(1) {
            margin-left: 0;
        }

        .analysis1 {
            width: 14.063vw;
            background-color: #901a76;
            border-radius: 0.521vw;
            padding: 0.781vw 1.25vw;

            .conclusion_t {
                font-family: MicrosoftYaHei-Bold;
                font-size: 0.833vw;
                letter-spacing: 0.042vw;
                color: #ffffff;
            }

            .conclusion {
                font-family: MicrosoftYaHei;
                font-size: 0.729vw;
                letter-spacing: 0.036vw;
                color: #ffffff;
                display: flex;
                align-items: center;

                .sizeconclusion {
                    display: flex;
                }

                div {
                    font-family: MicrosoftYaHei;
                    font-size: 0.729vw;
                    letter-spacing: 0.036vw;
                    color: #ffffff;
                }
            }
        }

        .analysis2 {
            .analysis_nr {
                width: 100%;
                height: 7.813vw;
                background-color: #eeecec;
                border-radius: 0.521vw;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: baseline;
                padding: 0.833vw 1.453vw;

                div {
                    margin-right: 1.456vw;
                }
            }
        }

        .analysis3 {
            .analysis_nr {
                width: 100%;
                height: 7.813vw;
                background-color: #fff;
                border-radius: 0.521vw;
            }
        }

        .analysis4 {
            .analysis_nr {
                width: 100%;
                height: 7.813vw;
                background-color: #eeecec;
                border-radius: 0.521vw;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: baseline;
                padding: 0.833vw 1.453vw;

                div {
                    margin-right: 1.456vw;
                }
            }
        }
    }

    .cont_actable {
        width: 100%;
        display: flex;
        background-color: #e1e1e1;
        padding-top: 0.521vw;
        display: flex;
        justify-content: space-between;
        border-radius: 0 0 .7292vw .7292vw;

        .cont_act_l {
            width: 11.979vw;
            height: 26.25vw;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            border-radius: 0 0 0 .7292vw;

            .cont_act {
                background-color: #edf3ff;
            }

            .cont_act_t {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                .xian {
                    width: 0.313vw;
                    height: 100%;
                    background-color: transparent;
                }

                .xian2 {
                    background-color: #0f5df9;
                }

                .cont_t {
                    font-family: MicrosoftYaHei;
                    font-size: 0.833vw;
                    letter-spacing: 0.042vw;
                    color: #333333;
                    padding: 0.677vw 0;
                }

                .cont_t_act {
                    color: #0f5df9;
                }
            }
        }

        .cont_table_r {
            width: 57.188vw;
            height: 26.25vw;
            padding: 0.521vw;
            background-color: #fff;
            border-radius: 0 0 .7292vw 0;

            /deep/.table {
                width: 100%;
                // height: 100%;
            }
        }
    }
}



.aFu_span {
    color: #2C72FF;
    cursor: pointer;
}

.aFu_span img {
    max-width: 15.729vw;
    max-height: 9.259vh;
    height: auto;
    width: auto;
    display: block;
}

/deep/.el-image__inner {
    max-width: 4.167vw;
    max-height: 3.704vh;
    width: auto;
    height: auto;
}
</style>