var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data.flag)?_c('div',{staticClass:"mask"},[_c('div',{staticClass:"mask-main"},[_c('div',{staticClass:"mask-header"},[_c('div',{staticClass:"mask-left"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),_c('i',{staticClass:"el-icon-close posi",on:{"click":_vm.handleClose}})]),_c('div',{staticClass:"mask-line"}),_c('div',{staticClass:"mask-table"},[_c('div',{staticClass:"mask-lang"},[_vm._v("服装列表")]),_c('el-table',{ref:"multipleTable",staticClass:"center_table",staticStyle:{"width":"100%"},attrs:{"max-height":"500","data":_vm.importArr,"tooltip-effect":"dark","border":"","header-cell-style":{
                    width: 72.9699 + 'vw',
                    height: 5.185 + 'vh',
                    background: '#F9F9F9',
                    fontSize: 0.833 + 'vw',
                    textAlign: 'center',

                }}},[_c('el-table-column',{attrs:{"align":"center","prop":"bianhao","label":"物资ID","min-width":"18.6px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"aFu_span",on:{"click":function($event){return _vm.handlerTo(scope.row.id)}}},[_vm._v(_vm._s(scope.row.bianhao))])]}}],null,false,1149604174)}),_c('el-table-column',{attrs:{"prop":"name","align":"center","label":"物资名称","show-overflow-tooltip":"","min-width":"16.7px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"locator_id","label":"库位编号","show-overflow-tooltip":"","min-width":"12.5px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"counts","label":"数量","show-overflow-tooltip":"","min-width":"9.7px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"cp","label":"物资实物","show-overflow-tooltip":"","min-width":"23.7px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"aFu",on:{"contextmenu":_vm.disableRightClick}},[_c('el-image',{staticStyle:{"max-width":"4.167vw !important","max-height":"3.704vh!important","width":"auto !important","height":"auto!important"},attrs:{"src":_vm.imgurl + scope.row.images},on:{"click":function($event){return _vm.bigimgclick(_vm.imgurl + scope.row.images)}}})],1)]}}],null,false,387328730)}),_c('el-table-column',{attrs:{"align":"center","prop":"cate","label":"物资分类","show-overflow-tooltip":"","min-width":"8.8px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"sex","label":"适用性别","show-overflow-tooltip":"","min-width":"8.9px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"createtime","label":"创建时间","show-overflow-tooltip":"","min-width":"12.0px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"adminname","label":"创建人","show-overflow-tooltip":"","min-width":"15.8px"}})],1)],1)]),_c('ImgPreview',{attrs:{"data":_vm.imgpreviewObj},on:{"imgpreviewCall":_vm.imgpreviewCall}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }