<template>
  <div class="order-mask" v-if="data.flag">
    <!-- 上传底片 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div class="mask-title">
          <div></div>
          {{ data.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="table">
        <div class="message">
          <div class="message-item">
            <div class="message-title xin2">标题</div>
            <div class="message-main">
              <el-input
                v-model="users.title"
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>
          <div class="message-item">
            <div class="message-title xin2">消息内容</div>
            <div class="message-main">
              <el-input
                type="textarea"
                v-model="users.info"
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>
          <div class="message-item">
            <div class="message-title xin2">图片</div>
            <div class="message-main">
              <div class="cen-table upload">
                <el-upload
                  :action="uploadimg"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  :headers="headers"
                  accept="image/jpg,image/jpeg,image/png"
                  :on-remove="handleRemove"
                  :show-file-list="true"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="users.images" alt="" />
                </el-dialog>
              </div>
            </div>
          </div>
        </div>
        <div class="nav-right">
          <button class="btn-color" @click="handleSubmit">保存</button>
          <button @click="handleRefund">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddAuth",
  props: ["data"],
  data() {
    return {
      radio: 3,
      headers: {
        token: localStorage.token,
      },
      users: {
        title: "",
        info: "",
        images: "",
      },
    };
  },
  mounted() {
    if (this.data.type == "edit") {
      this.users.name = this.data.value;
    }
  },
  methods: {
    //取消弹窗
    handleRefund() {
      let obj = {
        submitFlag: false,
      };
      this.$emit("AddNoticeCell", obj);
    },
    handleSubmit() {
      let obj = {
        flag: false,
        submitFlag: true,
        type: this.data.type,
        data: this.users,
      };
      this.$emit("AddNoticeCell", obj);
    },
    //图片上传成功
    handleAvatarSuccess(response, file, fileList) {
      if (response.code == 1) {
        this.users.images =
          JSON.parse(JSON.stringify(response.data.imgsrc.replace(/,/g, ""))) +
          "";
      }
    },
    beforeAvatarSuccess(file) {
      var img = file.name.substring(file.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg" || "JPG";
      const suffix2 = img === "png" || "PNG";
      const suffix3 = img === "jpeg" || "JPEG";
      if (!suffix && !suffix2 && !suffix3) {
        this.$message.error("只能上传图片！");
        return false;
      }
      return suffix || suffix2 || suffix3;
    },
  },
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask .mask-main {
  width: 37.917vw;
}

.table table {
  width: 37.917vw;
  margin-top: 1.852vh;
}

.table table input {
  padding-left: 1.25vw;
  font-size: 0.833vw;
}

.table {
  margin-top: 2.963vh;
}

.message-item {
  margin-bottom: 2.778vh;
}

.message-title {
  margin-bottom: 1.111vh;
}

.message-main {
  /deep/.el-input__inner {
    height: 4.907vh;
  }

  /deep/.el-textarea__inner {
    height: 23.704vh;
  }

  /deep/.el-upload--picture-card {
    width: 10.677vw;
    height: 11.111vh;
  }
}

.nav-right {
  display: flex;
  justify-content: center;
  margin-top: 4.074vh;

  button {
    width: 6.25vw;
    height: 4.259vh;
    background-color: #ffffff;
    border-radius: 0.521vw;
    border: solid 0.052vw #2c72ff;
    color: #2c72ff;
    margin-right: 2.708vw;
    cursor: pointer;
  }

  button:last-child {
    margin-right: 0;
  }

  .btn-color {
    background-color: #2c72ff;
    color: #fff;
  }
}

/deep/.message-main > .upload > div {
  display: flex;
  flex-wrap: wrap;
}

/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 7.708vw;
  height: 7.708vw;
  margin: 0 0.417vw 0.417vw 0;
  border: 0.052vw solid #c0ccda;
  border-radius: 0.313vw;
}

/deep/.el-upload--picture-card {
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 1.458vw;
  }
}
</style>