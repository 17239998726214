<template>
  <div class="order-mask" v-if="data.flag">
    <!-- 添加订单 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div class="mask-title">
          <div></div>
          {{ data.title }}
        </div>
        <i class="el-icon-close" @click="handleRefund"></i>
      </div>
      <div class="mask-content">
        <!-- <i class="el-icon-warning-outline"></i> -->
        <div>{{ data.text }}</div>
      </div>
      <div class="mask-footer">
        <button class="btn-bg" @click="handleSubmit">{{ data.btns }}</button>
        <button @click="handleRefund">{{ data.btnq }}</button>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "MaskPack",
  props: ['data'],
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    //取消弹窗
    handleRefund() {
      let data = {
        flag: false,
        submitFlag: false
      }
      this.$emit("childRef", data);
    },
    handleSubmit() {
      let data = {
        flag: false,//弹窗是否显示
        submitFlag: true//是否调用接口
      }
      this.$emit("childRef", data);
    },
  },
};
</script>
  
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask {
  .mask-main {
    width: 26.042vw;
    border-radius: 1.296vh 0.521vw 0.926vh 0.521vw;
  }

  .mask-content {
    display: flex;
    align-items: flex-start;
    font-size: 0.833vw;
    color: #333333;
    height: 7.407vh;
    margin: 2.407vh 0 1.852vh;

    i {
      font-size: 1.25vw;
      color: #066ff8;
      margin-right: 0.521vw;
    }
  }

  .mask-footer {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 7.813vw;
      height: 4.259vh;
      border-radius: 0.313vw;
      font-size: 0.729vw;
      cursor: pointer;
    }

    button {
      background: #fff;
      border: 0.052vw solid#066ff8;
      color: #066FF8;
      margin-right: 3.125vw;
    }

    button:last-child {
      margin-right: 0;
    }

    .btn-bg {
      background: #066ff8;
      border: 0.052vw solid#066ff8;
      color: #fff;
    }
  }
}
</style>