<template>
    <div class="tag">
        <!-- tag标签 -->
        <div class="tag-main" v-if="path != '/'">
            <el-tag v-for="tag in tags" :key="tag.name" closable type="info"
                :class="['tag', path == tag.router ? 'info-active' : '']" @close="handleClose(tag)" @click="handleTag(tag)">
                {{ tag.name }}
            </el-tag>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tags: [],
            shopname: "",
            path: ""
        }
    },
    mounted() {
        let arr = JSON.parse(window.sessionStorage.getItem('tag'));
        // console.log(arr);
        this.path = this.$route.path;

        if (arr == null || arr == "undefined" || arr.length == null || arr.length == "undefined" || arr.length <= 0) {
            // console.log(arr);
            arr = [];
            let obj = {
                name: this.$route.name,
                router: this.$route.path,
            }
            arr.push(obj)
            // console.log(arr,arr.push(obj));
            window.sessionStorage.setItem('tag', JSON.stringify(arr))

        } else {
            this.tags = arr;
        }
    },
    methods: {
        handleTag(item) {
            // console.log(item);
            this.$router.push(item.path);
        },
        handleClose(item) {
            let arr = this.tags;
            if (arr.length == 1) {
                this.$message.error("最少一个标签");
                return false;
            }
            if (item.router == this.path) {
                this.$message.error("当前页面已打开");
                return false;
            }
            this.tags.forEach((items, i) => {
                if (items.router == item.router) {
                    arr.splice(i, 1)
                }
            })
            this.tags = arr;
            window.sessionStorage.setItem('tag', JSON.stringify(arr))
        },
    },
    watch: {
        $route: {
            handler: function (val, oldVal) {
                // console.log(val);
                let arr = JSON.parse(window.sessionStorage.getItem('tag'));
                //  console.log(arr);
                // let arr = []
                if (arr.length >= 1) {
                    arr.forEach((item, i) => {
                        if (item.router == val.path) {
                            arr.splice(i, 1)
                            // console.log(arr);
                        }
                    })

                    // 修改tag显示 编辑与新增
                    // arr.forEach(element=>{

                    // })
                    let name = val.name;
                    if (val.path == "/appointmentInfo" && val.query.id) {
                        name = "编辑预约单";
                    } else if (val.path == "/addCalmetrics" && val.query.id) {
                        name = "编辑标准片";
                    } else if (val.path == "/addMaterial" && val.query.id) {
                        name = "编辑物资";
                    }


                    let obj = {
                        name: name,
                        router: val.path,
                        path: val.fullPath
                    }
                    arr.unshift(obj)
                    if (arr.length > 10) {
                        arr.pop();
                    }
                    this.tags = arr;
                    window.sessionStorage.setItem('tag', JSON.stringify(arr))

                }
                this.path = val.path;
                // console.log(val.query.id,arr);
            },
            // 深度观察监听
            deep: true,
            immediate: true
        }
    },
}
</script>

<style lang="less" scoped>
/* // tag标签 */
.tag-main {
    margin-bottom: 0.926vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    // padding-left: 2.083vw;
}

.app-main .el-tag--info {
    // width: 6.927vw;
    height: 3.519vh;
    background: #ffffff;
    border: 0.052vw solid#e1e1e1;
    border-radius: 0.521vw;
    text-align: center;
    line-height: 3.519vh;
    cursor: pointer;
}

.app-main .info-active {
    color: #2C72FF;
    border: 0.052vw solid#2C72FF;
}

.tag {
    margin-right: 0.521vw;
}

// 标签 -- 开始
/deep/.el-tag {
    padding: 0 0.521vw;
    font-size: 0.625vw;
}

/deep/.el-tag .el-icon-close {
    height: 1.481vh;
    width: 0.833vw;
    line-height: 1.481vh;
    top: -0.093vh;
    right: -0.26vw;
    font-size: 0.625vw;
}

// 标签 -- 结束
</style>