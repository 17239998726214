<template>
    <div class="order-mask" v-if="dataVal.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div></div>
                <div class="mask-title">
                    查看跟踪记录
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <el-table class="center_table" ref="multipleTable" :data="tableData.list" tooltip-effect="dark"
                    style="width: 98%" border :header-cell-style="{
                        width: 81.823 + 'vw',
                        height: 5.185 + 'vh',
                        background: '#F9F9F9',
                        fontSize: 0.729 + 'vw',
                        textAlign: 'center',
                    }" @selection-change="handleSelectionChange">
                    <el-table-column align="center" prop="id" type="index" label="序号" min-width="7.3px">
                    </el-table-column>
                    <el-table-column align="center" prop="adminname" label="跟踪人" min-width="11.8px">
                    </el-table-column>
                    <el-table-column align="center" prop="createtime" label="跟踪时间" min-width="20.4px">
                    </el-table-column>
                    <el-table-column align="center" prop="status" label="跟踪状态" min-width="12.6px">
                    </el-table-column>
                    <el-table-column align="center" prop="info" label="记录说明" min-width="37.9px">
                    </el-table-column>
                    <el-table-column align="center" prop="attachimages" label="附件" min-width="11.9px">
                        <template slot-scope="scope">
                            <div class="aFu_span" v-if="scope.row.attach != null && scope.row.attach != ''"
                                @click="handleSee(scope.row)">查看附件</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 底部分页 -->
            <div class="footer-page">
                <span>共{{ tableData.total_count }}条</span>
                <el-pagination popper-class="paging" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    background :current-page="page" :page-sizes="[10, 20, 30, 40]" :page-size="count"
                    layout="sizes, prev, pager, next, jumper" :total="tableData.total_count" :pager-count="5">
                </el-pagination>
            </div>
        </div>
        <!-- 查看附件 -->
        <ViewAttachmentst :dataValue="see" @attaCell="attaCell"></ViewAttachmentst>
    </div>
</template>
      
<script>
export default {
    name: "ViewBrowsinghistory",
    props: ["dataVal"],
    data() {
        return {
            page: 1,
            count: 10,
            id: '',
            statuslist: [
                {
                    value: 1,
                    label: "跟踪中"
                }, {
                    value: 2,
                    label: "无需跟踪"
                }, {
                    value: 3,
                    label: "已成交"
                }
            ],
            tableData: {
                list: [],
                total_count: 0
            },
            multipleTable: [],
            see: {
                flag: false,
                name: "查看附件"
            },
        };
    },
    mounted() { },
    methods: {
        handleSee(obj) {
            this.see.obj = obj
            this.see.flag = true;
        },
        attaCell() {
            this.see.flag = false;
        },
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                typeText: ""
            }
            this.$emit("browsinghistoryCell", obj);
        },
        handleSubmit() {
            this.handleRefund();
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleSelectionChange(val) {
            this.multipleSelection = []
            val.forEach(element => {
                this.multipleSelection.push(element.id)
            });
            this.multipleSelection = JSON.parse(JSON.stringify(this.multipleSelection)) + ""
            //  console.log(this.multipleSelection)
        },
        handleSizeChange(val) {
            this.count = val
        },
        handleCurrentChange(e) {
            this.page = e
        },
        async spreadShowfollow() {
            let res = await this.$api.spreadShowfollow({
                page: this.page,
                count: this.count,
                id: this.id
            })
            if (res.code == 1) {
                this.tableData = res.data
                this.tableData.list.forEach(item => {
                    if (item.attach != null && item.attach != '') {
                        item.attach = item.attach.split(',')
                        for (let i = 0; i < item.attach.length; i++) {
                            item.attach[i] = this.imgurl + item.attach[i]
                        }
                        item.attachimages = JSON.parse(JSON.stringify(item.attach)) + ''
                    }

                    item.createtime = this.$FilDate.filDate(item.createtime * 1000)
                    this.statuslist.forEach(element => {
                        if (item.status == element.value) {
                            item.status = element.label
                        }
                    })
                })
            }
        }
    },
    watch: {
        dataVal: {
            deep: true,
            handler(newvalue, oldvalue) {
                this.id = newvalue.id;
                this.spreadShowfollow()
            }
        }
    }
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");
@import url("@/assets/css/table.less");

.order-mask .mask-main {
    width: 57.552vw;
    box-sizing: border-box;
}

.aFu_span {
    color: #2C72FF;
    cursor: pointer;
}

.aFu_span img {
    max-width: 15.729vw;
    max-height: 9.259vh;
    height: auto;
    width: auto;
    display: block;
}

/deep/.table {
    padding-top: 1.852vh;

    textarea {
        height: 33.333vh;
        resize: none;
    }
}
</style>