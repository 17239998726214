<template>
    <div class="order-mask" v-if="data.flag">
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    {{ data.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <div class="qrcode">
                    <img style="width: auto;height: 12.76vw;" :src="imgurl + image" alt="">
                </div>

            </div>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "BindingAccount",
    props: ["data"],
    data() {
        return {
            image: "",
        };
    },
    mounted() {

    },
    methods: {
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false
            }
            this.$emit("bindingAccountCell", obj);
        },
        async adminRebind() {
            if (this.data.id) {
                let data = {
                    id: this.data.id
                }
                //  console.log(data)
                let res = await this.$api.adminRebind(data)
                if (res.code == 1) {
                    //  console.log(res.data.qrcode);
                    this.image = res.data.qrcode.replace(/,/g, "")
                }
            }
        }
    },
    created() {
        this.adminRebind()
    },
    watch: {
        data: {
            deep: true,
            handler(newvalue, oldvalue) {
                this.adminRebind()
            }
        }
    }
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask .mask-main {
    width: 32.969vw;
}

.qrcode {
    max-width: 12.76vw;
    max-height: 12.76vw;
    margin: 4.167vh auto 0;
}
</style>