<template>
    <div class="order-mask" v-if="dataVal.flag">
        <!-- 一级分类 二级分类 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    {{ dataVal.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <table>
                    <tr v-if="dataVal.title1">
                        <td class="table-td xin2">{{ dataVal.title1 }}</td>
                        <td colspan="3">
                            <el-input type="text" v-model="users.name" :placeholder="'请输入' + dataVal.title1"
                                :maxlength="dataVal.maxlength1" show-word-limit></el-input>
                        </td>
                    </tr>
                    <tr v-if="dataVal.title2">
                        <td class="table-td">{{ dataVal.title2 }}</td>
                        <td colspan="3">
                            <el-input type="text" v-model="users.name_en" :placeholder="'请输入' + dataVal.title2"
                                :maxlength="dataVal.maxlength2" show-word-limit></el-input>
                        </td>
                    </tr>
                    <tr v-if="dataVal.title3">
                        <td class="table-td">
                            <div class="xin2">{{ dataVal.title3 }}</div>
                            <div>(建议尺寸1500*760)</div>
                        </td>
                        <td colspan="3">
                            <div class="unloadimg">
                                <el-upload :action="uploadimg" :headers="headers" drag :show-file-list="true"
                                    accept=".png,.jpg,.jpeg" :on-success="handleAvatarSuccess"
                                    :before-upload="beforeAvatarUpload">
                                    <img class="uploadimage" v-if="users.image != '' && users.image != null"
                                        :src="imgurl + users.image" alt="">
                                    <div v-if="users.image == '' || users.image == null" class="el-upload__text">
                                        点击或拖拽文件到此处上传
                                    </div>
                                </el-upload>
                            </div>
                        </td>
                    </tr>
                </table>
                <div class="footer">
                    <div v-if="dataVal.btn != '' && show" class="save" @click="handleSubmit">{{ dataVal.btn }}</div>
                    <div v-if="dataVal.btn1 != '' && show" class="save close" @click="handleRefund">{{ dataVal.btn1 }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "AddAuth",
    props: ["dataVal"],
    data() {
        return {
            radio: 3,
            users: {
                name: '',
                name_en: '',
                image: '',
                status: '',
                pid: '',
                id: '',
            },
            image: '',
            headers: {
                token: localStorage.token
            },
            show: true,
            imageslist: [],
        };
    },
    mounted() {

    },
    methods: {
        handleAvatarSuccess(response, res, file) {
            if (response.code == 1) {
                this.users.image = response.data.imgsrc;
                this.show = true;
            }
        },
        beforeAvatarUpload(file) {
            var img = file.name.substring(file.name.lastIndexOf('.') + 1)
            const suffix = img === 'jpg'
            const suffix2 = img === 'png'
            const suffix3 = img === 'jpeg'
            if (!suffix && !suffix2 && !suffix3) {
                this.$message.error("只能上传图片！");
                return false
            }
            const isLt2M = file.size / 1024 / 1024 < 20;
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
                return false
            }
            this.show = false;
            return suffix || suffix2 || suffix3
        },
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                typeText: "",
                submitFlag: false
            }
            this.$emit("classificationCall", obj);
        },
        handleSubmit() {
            let obj = {
                flag: false,
                typeText: "",
                submitFlag: true,
                data: this.users
            }
            this.$emit("classificationCall", obj);
        },
    },
    watch: {
        dataVal: {
            deep: true,
            handler(newvalue, oldvalue) {
                if (newvalue.type == 1 || newvalue.type == 3) {
                    this.users = {
                        name: '',
                        name_en: '',
                        image: '',
                        status: '',
                        pid: '',
                        id: '',
                    }
                } else if (newvalue.type == 2) {
                    this.users = {
                        name: newvalue.obj.name,
                        name_en: '',
                        image: '',
                        status: '',
                        pid: '',
                        id: newvalue.obj.id,
                    }
                } else if (newvalue.type == 4) {
                    this.users = {
                        name: newvalue.obj.name,
                        name_en: newvalue.obj.name_en,
                        image: newvalue.obj.image,
                        status: newvalue.obj.status,
                        pid: newvalue.obj.pid,
                        id: newvalue.obj.id,
                    }
                }
            }
        }
    }
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask .mask-main {
    width: 41.667vw;
    // padding: 2.315vh 1.823vw 3.241vh;
    padding: 0;

    .mask-nav {
        height: 3.646vw;
        display: flex;
        align-items: center;
        background-color: #0f5df9;
        border-radius: 1.296vh 0.521vw 0 0;
        padding: 0 1.823vw;

        .mask-title {
            color: #fff;
        }

        i {
            color: #fff;
        }
    }

    .table {
        padding: 0 1.823vw;
    }
}

.table table {
    width: 41.667vw;
    margin-top: 1.852vh;

    td {
        padding: 0;
    }
}

.table table input {
    padding-left: 1.25vw;
    font-size: 0.833vw;
}

/deep/.el-input .el-input__inner {
    width: 100%;
    height: 100%;
    border: 0;
}

// .unloadimg {
//     width: 100%;
//     height: 100%;

//     /deep/.el-upload--picture-card {
//         border: 0;
//         border-radius: .3125vw;
//         width: 100%;
//         height: 100%;
//         line-height: 7.6042vw;

//         .el-upload-dragger {
//             width: 100%;
//             height: 100%;
//             border: 0;
//             border-radius: .3125vw;

//             .el-upload__text {
//                 font-size: .8333vw;
//                 color: #b2afaf;
//                 background-color: #f2f2f2;
//             }
//         }
//     }
// }
.unloadimg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5208vw;

    .uploadimage {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }
}

/deep/.el-upload {
    display: flex;
    justify-content: center;
    align-items: center;

    .el-upload-dragger {
        width: 27.708vw;
        height: 10.938vw;
        border: 0;
        border-radius: .3125vw;
        background-color: #f2f2f2;
        display: flex;
        justify-content: center;
        align-items: center;

        .el-upload__text {
            font-size: .8333vw;
            color: #b2afaf;

        }
    }
}


.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1.0417vw;
    margin-bottom: 1.0417vw;

    .save {
        padding: 0;
        margin: 0;
    }

    .close {
        color: #2c72ff;
        background-color: #ffffff;
        border: solid .0521vw #2c72ff;
        margin-left: 1.5104vw;
    }
}

/deep/.el-upload-list {
    display: none;
}
</style>