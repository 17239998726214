import { render, staticRenderFns } from "./TipModel.vue?vue&type=template&id=f2d4c812&scoped=true"
import script from "./TipModel.vue?vue&type=script&lang=js"
export * from "./TipModel.vue?vue&type=script&lang=js"
import style0 from "./TipModel.vue?vue&type=style&index=0&id=f2d4c812&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2d4c812",
  null
  
)

export default component.exports