import Vue from 'vue'
import Home from '@/views/Home.vue'
import Router from 'vue-router'

// Vue.use(Router)
//防止路由重复报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//路由
export const constantRouterMap = [
    //首页
    {
        path: '/',
        component: Home,
        children: [
            // 二级路由首页
            {
                id: '1',
                path: "/",
                component: () => import('@/views/home/AppMain.vue'),
                redirect: '/',
                children: [
                    // 首页
                    {
                        id: '6',
                        path: "/",
                        name: "首页",
                        component: () => import('@/views/home/homePage/Index.vue')
                    },
                    // 数据统计
                    {
                        id: '8',
                        path: "/dataStatistics",
                        name: "数据统计",
                        component: () => import('@/views/home/homePage/DataStatistics/DataStatistics.vue'),
                        children: [
                            // 标准片统计
                            {
                                path: "/standardStatistics",
                                name: "标准片统计",
                                component: () => import('@/views/home/homePage/DataStatistics/child/StandardStatistics.vue')
                            },
                            // 预约单统计
                            {
                                path: "/appointmentStatistics",
                                name: "预约单统计",
                                component: () => import('@/views/home/homePage/DataStatistics/child/AppointmentStatistics.vue')
                            },
                        ]
                    },
                    // 点拍/重拍/更换明细
                    {
                        id: '8',
                        path: "/replacementDetails",
                        name: "点拍/重拍/更换明细",
                        component: () => import('@/views/home/homePage/DataStatistics/ReplacementDetails.vue')
                    },
                    // 评价率/好评率明细
                    {
                        id: '8',
                        path: "/evaluationRate",
                        name: "评价率/好评率明细",
                        component: () => import('@/views/home/homePage/DataStatistics/EvaluationRate.vue')
                    },
                    //登录日志
                    {
                        id: '9',
                        path: "/loginLog",
                        name: "登录日志",
                        component: () => import('@/views/home/homePage/LoginLog.vue')
                    },
                    //操作日志
                    {
                        id: '10',
                        path: "/operationLog",
                        name: "操作日志",
                        component: () => import('@/views/home/homePage/OperationLog.vue')
                    },
                    //软件服务费
                    {
                        id: '0',
                        path: "/software",
                        name: "软件服务费",
                        component: () => import('@/views/home/homePage/Software.vue')
                    },
                ]
            },
            //管理中心
            {
                id: '2',
                path: "/management",
                component: () => import('@/views/management/AppMain.vue'),
                redirect: '/reservation',
                children: [
                    //预约单管理
                    {
                        id: '11',
                        path: "/reservation",
                        name: "预约单管理",
                        component: () => import('@/views/management/managementPage/reservation/Reservation.vue')
                    },
                    //新增预约单
                    {
                        id: '11',
                        path: "/appointmentInfo",
                        name: "新增预约单",
                        component: () => import('@/views/management/managementPage/reservation/AppointmentInfo.vue')
                    },
                    //预约单管理详情
                    {
                        id: '11',
                        path: "/reservationDetail",
                        component: () => import('@/views/management/managementPage/reservation/ReservationDetail.vue'),
                        redirect: '/basicInfo',
                        children: [
                            //基本资料
                            {
                                path: "/basicInfo",
                                name: "基本资料",
                                component: () => import('@/views/management/managementPage/reservation/child/BasicInfo.vue')
                            },
                            //服装信息
                            {
                                path: "/clothingInfo",
                                name: "服装信息",
                                component: () => import('@/views/management/managementPage/reservation/child/ClothingInfo.vue')
                            }
                        ]
                    },
                    //标准片管理
                    {
                        id: '21',
                        path: "/calmetrics",
                        name: "标准片管理",
                        component: () => import('@/views/management/managementPage/calmetrics/Calmetrics.vue')
                    },
                    //标准片管理详情
                    {
                        id: '21',
                        path: "/calmetricsDetail",
                        name: "标准片管理详情",
                        component: () => import('@/views/management/managementPage/calmetrics/CalmetricsDetail.vue')
                    },
                    //添加标准片
                    {
                        id: '21',
                        path: "/addCalmetrics",
                        name: "添加标准片",
                        component: () => import('@/views/management/managementPage/calmetrics/AddCalmetrics.vue')
                    },
                    //客户管理
                    {
                        id: '19',
                        path: "/customer",
                        name: "客户管理",
                        component: () => import('@/views/management/managementPage/customer/Customer.vue')
                    },
                    //消息管理
                    {
                        id: '36',
                        path: "/message",
                        component: () => import('@/views/management/managementPage/message/Message.vue'),
                        redirect: '/noticeMessage',
                        children: [
                            //通知消息
                            {
                                path: "/noticeMessage",
                                name: "通知消息",
                                component: () => import('@/views/management/managementPage/message/child/NoticeMessage.vue')
                            },
                            //系统消息
                            {
                                path: "/systemMessage",
                                name: "系统消息",
                                component: () => import('@/views/management/managementPage/message/child/SystemMessage.vue')
                            },
                        ]
                    },
                    //广告管理
                    {
                        id: '42',
                        path: "/advertisement",
                        name: "广告管理",
                        component: () => import('@/views/management/managementPage/advertisement/Advertisement.vue')
                    },
                    //评价管理
                    {
                        id: '39',
                        path: "/evaluation",
                        name: "评价管理",
                        component: () => import('@/views/management/managementPage/evaluation/Evaluation.vue')
                    },
                    //填写处理进程
                    {
                        id: '39',
                        path: "/addEvaluation",
                        name: "填写处理进程",
                        component: () => import('@/views/management/managementPage/evaluation/AddEvaluation.vue')
                    },
                    //模特管理
                    {
                        id: '32',
                        path: "/modelManagement",
                        name: "模特管理",
                        component: () => import('@/views/management/managementPage/modelManagement/ModelManagement.vue')
                    },
                    //意见反馈
                    {
                        id: '69',
                        path: "/feedback",
                        name: "意见反馈",
                        component: () => import('@/views/management/managementPage/feedback/Feedback.vue')
                    },
                    //填写处理进程
                    {
                        id: '69',
                        path: "/AddFeedback",
                        name: "填写意见处理进程",
                        component: () => import('@/views/management/managementPage/feedback/AddFeedback.vue')
                    },
                    //物资管理
                    {
                        id: '26',
                        path: "/material",
                        name: "物资管理",
                        component: () => import('@/views/management/managementPage/material/Material.vue')
                    },
                    //物资批量关联
                    {
                        id: '26',
                        path: "/associationMaterial",
                        name: "批量关联标准图库",
                        component: () => import('@/views/management/managementPage/material/AssociationMaterial.vue')
                    },
                    //物资管理详情
                    {
                        id: '26',
                        path: "/materialDetail",
                        name: "物资管理详情",
                        component: () => import('@/views/management/managementPage/material/MaterialDetail.vue')
                    },
                    //添加物资
                    {
                        id: '26',
                        path: "/addMaterial",
                        name: "添加物资",
                        component: () => import('@/views/management/managementPage/material/AddMaterial.vue')
                    },
                    
                ]
            },
            //图库管理
            {
                id: '3',
                path: "/galleryMall",
                component: () => import('@/views/galleryMall/AppMain.vue'),
                redirect: '/galleryshop',
                children: [
                    //图库商城
                    {
                        id: '70',
                        path: "/galleryshop",
                        name: "图库商城",
                        component: () => import('@/views/galleryMall/galleryPage/galleryshop/galleryshop.vue')
                    },
                    //名师推荐详情
                    {
                        id: '70',
                        path: "/teacherDetail",
                        name: "名师推荐详情",
                        component: () => import('@/views/galleryMall/galleryPage/galleryshop/teacherDetail.vue')
                    },
                    //图库详情
                    {
                        id: '70',
                        path: "/imageDetail",
                        name: "图库详情",
                        component: () => import('@/views/galleryMall/galleryPage/galleryshop/imageDetail.vue')
                    },
                    //购物车
                    {
                        id: '71',
                        path: "/shoppingcart",
                        name: "购物车",
                        component: () => import('@/views/galleryMall/galleryPage/shoppingcart/shoppingcart.vue')
                    },
                    //已买到的宝贝
                    {
                        id: '72',
                        path: "/purchased",
                        name: "已买到的宝贝",
                        component: () => import('@/views/galleryMall/galleryPage/purchased/purchased.vue')
                    },
                    //我的收藏
                    {
                        id: '73',
                        path: "/mycollection",
                        name: "我的收藏",
                        component: () => import('@/views/galleryMall/galleryPage/mycollection/mycollection.vue')
                    },
                    //提现申请
                    {
                        id: '0',
                        path: "/withdrawalapplication",
                        name: "提现申请",
                        component: () => import('@/views/galleryMall/galleryPage/withdrawalapplication/withdrawalapplication.vue')
                    },
                    //我的账户
                    {
                        id: '74',
                        path: "/account",
                        name: "我的账户",
                        component: () => import('@/views/galleryMall/galleryPage/account/account.vue')
                    },
                ]
            },
            //品宣管理
            {
                id: '85',
                path: "/pinxuan",
                component: () => import('@/views/pinxuan/AppMain.vue'),
                redirect: '/customerManagement',
                children: [
                    // 品宣客户管理
                    {
                        id: '86',
                        path: "/customerManagement",
                        name: "品宣客户管理",
                        component: () => import('@/views/pinxuan/pinxuanPage/customerManagement/customerManagement.vue')
                    },
                    // 填写跟踪记录
                    {
                        id: '86',
                        path: "/addtrackrecord",
                        name: "填写跟踪记录",
                        component: () => import('@/views/pinxuan/pinxuanPage/customerManagement/addtrackrecord.vue')
                    },
                    // 客户详情
                    {
                        id: '86',
                        path: "/customerDetails",
                        name: "客户详情",
                        component: () => import('@/views/pinxuan/pinxuanPage/customerManagement/customerDetails.vue'),
                        children: [
                            //基本信息
                            {
                                path: "/basicInformation",
                                name: "基本信息",
                                component: () => import('@/views/pinxuan/pinxuanPage/customerManagement/child/basicInformation.vue')
                            },
                            //浏览记录
                            {
                                path: "/browsingHistory",
                                name: "浏览记录",
                                component: () => import('@/views/pinxuan/pinxuanPage/customerManagement/child/browsingHistory.vue')
                            },
                            //跟踪记录
                            {
                                path: "/trackrecord",
                                name: "跟踪记录",
                                component: () => import('@/views/pinxuan/pinxuanPage/customerManagement/child/trackrecord.vue')
                            },]
                    },
                    // 样式管理
                    {
                        id: '87',
                        path: "/styleManagement",
                        name: "样式管理",
                        component: () => import('@/views/pinxuan/pinxuanPage/styleManagement/styleManagement.vue'),
                        children: [
                            //品宣首页
                            {
                                path: "/styleIndex",
                                name: "品宣首页",
                                component: () => import('@/views/pinxuan/pinxuanPage/styleManagement/child/styleIndex.vue')
                            },
                            //童研
                            {
                                path: "/childResearch",
                                name: "童研",
                                component: () => import('@/views/pinxuan/pinxuanPage/styleManagement/child/childResearch.vue')
                            },
                            //风格
                            {
                                path: "/styleMode",
                                name: "风格",
                                component: () => import('@/views/pinxuan/pinxuanPage/styleManagement/child/styleMode.vue')
                            },
                            //品牌
                            {
                                path: "/styleBrand",
                                name: "品牌",
                                component: () => import('@/views/pinxuan/pinxuanPage/styleManagement/child/styleBrand.vue')
                            },
                        ]
                    },
                    // 新增记录
                    {
                        id: '87',
                        path: "/addingRecord",
                        name: "新增记录",
                        component: () => import('@/views/pinxuan/pinxuanPage/styleManagement/addingRecord.vue')
                    },
                ]
            },
            //系统设置
            {
                id: '4',
                path: "/systemSettings",
                component: () => import('@/views/systemSettings/AppMain.vue'),
                redirect: '/authorization',
                children: [
                    //功能授权
                    {
                        id: '51',
                        path: "/authorization",
                        name: "功能授权",
                        component: () => import('@/views/systemSettings/systemSettingsPage/authorization/Authorization.vue')
                    },
                    //子账号管理
                    {
                        id: '55',
                        path: "/subAccount",
                        name: "子账号管理",
                        component: () => import('@/views/systemSettings/systemSettingsPage/subAccount/SubAccount.vue')
                    },
                    // //系统美化
                    // {
                    //     id: '0',
                    //     path: "/systemBeautification",
                    //     name: "系统美化",
                    //     component: () => import('@/views/systemSettings/systemSettingsPage/systemBeautification/SystemBeautification.vue')
                    // },
                    //模特协议
                    {
                        id: '59',
                        path: "/modelAgreement",
                        name: "模特协议",
                        component: () => import('@/views/systemSettings/systemSettingsPage/modelAgreement/ModelAgreement.vue')
                    },
                    //库存管理
                    {
                        id: '61',
                        path: "/inventory",
                        name: "库位管理",
                        component: () => import('@/views/systemSettings/systemSettingsPage/inventory/Inventory.vue')
                    },
                    //数据字典
                    {
                        id: '65',
                        path: "/dataDictionary",
                        name: "数据字典",
                        component: () => import('@/views/systemSettings/systemSettingsPage/dataDictionary/DataDictionary.vue')
                    },
                    //拍摄主题
                    {
                        id: '46',
                        path: "/shootingTheme",
                        name: "拍摄主题",
                        component: () => import('@/views/management/managementPage/shootingTheme/ShootingTheme.vue')
                    },
                    //添加物资
                    {
                        id: '88',
                        path: "/childModelLabel",
                        name: "类型管理",
                        component: () => import('@/views/systemSettings/systemSettingsPage/childModelLabel/ChildModelLabel.vue')
                    },
                ]
            }
        ]
    },
    //登录
    {
        path: "/login",
        name: "login",
        component: () => import('@/views/login/Login.vue')
    },
    //登录
    {
        path: "/forgotPassword",
        component: () => import('@/views/login/ForgotPassword.vue')
    }
]

// 路由鉴权
if (localStorage.getItem("role_id")) {
    // if (localStorage.getItem("role_id") != 1) {
    if (localStorage.getItem('menu_ids')) {
        let menu_ids = localStorage.getItem('menu_ids');
        menu_ids = JSON.parse(menu_ids);
        constantRouterMap[0].children = constantRouterMap[0].children.filter(element => {
            return menu_ids.includes(element.id)
        })
        constantRouterMap[0].children.forEach(item => {
            item.children = item.children.filter(element => {
                return menu_ids.includes(element.id)
            })
        })
        constantRouterMap[0].children.forEach(item => {
            if (item.children.length > 0) {
                item.redirect = item.children[0].path
            }
        })
    }
    // }
}
const router = new Router({
    routes: constantRouterMap
})
router.beforeEach(async (to, from, next) => {
    let token = localStorage.getItem('token');
    // let arr = ['/login', '/forgotPassword']
    // if (arr.indexOf(to.name) != -1 && !token) {
    //     next({ name: 'login' })
    // } else {
    //     next()
    // }

    if (to.name == "login") {
        next()
    } else if (to.name == null) {
        next()
    } else {
        if (!token) {
            next({ name: 'login' })
        } else {
            // next()
            constantRouterMap.forEach(item => {
                if (item.name == to.name) {
                    // console.log(to.name);
                    next()
                }
                if (item.children) {
                    item.children.forEach(element => {
                        if (element.name == to.name) {
                            // console.log(to.name);
                            next()
                        }
                        if (element.children) {
                            element.children.forEach(evt => {
                                if (evt.name == to.name) {
                                    // console.log(to.name);
                                    next()
                                }

                                if (evt.children) {
                                    evt.children.forEach(e => {
                                        if (e.name == to.name) {
                                            // console.log(to.name);
                                            next()
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }
    }
})

export default router