<template>
    <div class="order-mask" v-if="dataVal.flag">
        <!-- 发布 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    {{ dataVal.title }}
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <div class="tabletip">您可以将您所拍摄的标准片文件发布至图库商城中，来获取一定的报酬。</div>
                <table>
                    <tr>
                        <td class="table-td xin2">售卖价格</td>
                        <td colspan="3">
                            <input type="text" v-model="price" :placeholder="'请输入售卖价格(元)'">
                        </td>
                    </tr>
                </table>
                <div class="serviceTerm">
                    <el-checkbox v-model="checked"></el-checkbox>
                    <div class="term1">我已阅读并同意</div>
                    <div class="term1 term2">《标准片发布须知》</div>
                </div>
                <div v-if="dataVal.btn != ''" class="save" @click="handleSubmit">{{ dataVal.btn }}</div>
            </div>
        </div>
    </div>
</template>
      
<script>
export default {
    name: "Release",
    props: ["dataVal"],
    data() {
        return {
            price: '',
            checked: false
        };
    },
    mounted() {

    },
    methods: {
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                submitFlag: false
            }
            this.$emit("releaseCall", obj);
        },
        handleSubmit() {
            if (this.checked == false) {
                this.$message.error('请阅读并同意《标准片发布须知》');
                return false;
            }
            let reg = /^[0-9]+([.]{1}[0-9]+){0,1}$/
            if (!reg.test(this.price)) {
                this.$message.error('请输入正确的价格');
                return false;
            }
            let obj = {
                flag: false,
                price: this.price,
                submitFlag: true
            }
            this.$emit("releaseCall", obj);
        },
    },
    watch: {
        dataVal: {
            deep: true,
            handler(newvalue, oldvalue) {
                this.price = ''
                this.checked = false
            }
        }
    }
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

/deep/.el-textarea__inner {
    height: 14.259vh;
    border: none;
    resize: none;
}

.order-mask .mask-main {
    width: 32.969vw;
}

.table table {
    width: 32.969vw;
    margin-top: 1.852vh;
}

.table table input {
    padding-left: 1.25vw;
    font-size: 0.833vw;
}

.xin2 {
    font-weight: bold;
}

.tabletip {
    font-size: .8333vw;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-top: .5208vw;
}

.serviceTerm {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    /deep/.el-checkbox__inner {
        border-radius: 50% !important;
    }

    .term1 {
        font-size: .8333vw;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-left: .5208vw;
    }

    .term2 {
        color: #2C72FF;
        cursor: pointer;
    }
}
</style>