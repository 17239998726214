<template>
  <div class="order-mask" v-if="dataVal.flag">
    <!-- 上传底片 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div class="mask-title">
          <div></div>
          {{ dataVal.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="table">
        <table>
          <tr>
            <td class="table-td xin2">客户姓名</td>
            <td colspan="3">
              <input type="text" v-model.trim="users.name" :placeholder="'请输入客户姓名'">
            </td>
          </tr>
          <tr>
            <td class="table-td xin2">手机号</td>
            <td colspan="3">
              <input type="text" v-model.trim="users.phone" :placeholder="'请输入手机号'">
            </td>
          </tr>
        </table>
        <div v-if="dataVal.btn != ''" class="save" @click="handleSubmit">{{ dataVal.btn }}</div>
      </div>
    </div>
  </div>
</template>
      
<script>
export default {
  name: "AddAuth",
  props: ["dataVal"],
  data() {
    return {
      radio: 3,
      users: {
        name: "",
        phone: "",
      }
    };
  },
  created() {

  },
  methods: {
    //取消弹窗
    handleRefund() {
      this.$emit("editCustomerCell", { flag: false, flags: true });
    },
    handleSubmit() {
      let obj = {
        flag: false,
        id: this.id,
        name: this.users.name,
        phone: this.users.phone,
      }
      let phonereg = /^1[3-9][0-9]{9}$/
      if (phonereg.test(this.users.phone)) {
        this.$emit("editCustomerCell", obj);
      } else {
        this.$message({
          type: "error",
          message: "手机号码格式错误"
        })
      }

    },
  },
  watch: {
    dataVal: {
      deep: true,
      handler(newV, oldV) {
        if (newV.type == 'edit') {
          this.users.name = newV.users.name;
          this.users.phone = newV.users.phone;
        }
      }
    }
  }
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask .mask-main {
  width: 32.969vw;
}

.table table {
  width: 32.969vw;
  margin-top: 1.852vh;
}

.table table input {
  padding-left: 1.25vw;
  font-size: 0.833vw;
}
</style>