//全局注册组件
//拍摄完成/拍摄未完成弹窗
import Tag from '@/components/Tag.vue';
//拍摄完成/拍摄未完成弹窗
import Capture from '@/components/management/Capture.vue';
//批量推送预约单
import MaskPack from '@/components/management/MaskPack.vue';
//上传底片
import FailureReason from '@/components/management/FailureReason.vue';
//批量导入
import BatchImport from '@/components/management/BatchImport.vue';
//预约信息查看
import Meservation from '@/components/management/Meservation.vue';
//添加角色
import AddAuth from '@/components/system/AddAuth.vue';
//添加子账号
import AddAccount from '@/components/system/AddAccount.vue';
//新增请求参数
import AddParameters from '@/components/system/AddParameters.vue';
//关联物资
import ViewSupplies from '@/components/management/calmetrics/ViewSupplies.vue';
//修改客户信息
import EditCustomer from '@/components/management/customer/EditCustomer.vue';
//添加通知消息
import AddNotice from '@/components/management/message/AddNotice.vue';
//添加通知消息
import AddTheme from '@/components/management/shootingTheme/AddTheme.vue';
//添加广告
import AddAdvertisement from '@/components/management/advertisement/AddAdvertisement.vue';
//查看附件
import ViewAttachments from '@/components/management/evaluation/ViewAttachments.vue';
//查看附件2
import ViewAttachmentst from '@/components/pinxuan/ViewAttachmentst.vue';
//查看处理结果
import ProcessingProgress from '@/components/management/evaluation/ProcessingProgress.vue';
//新增模特
import AddModelManagment from '@/components/management/model/AddModelManagment.vue';
//服装品控用户协议
import TipModel from '@/components/management/model/TipModel.vue';
//签订协议，失败原因，重新发送
import FileModel from '@/components/management/model/FileModel.vue';
//关联物资
import ViewMaterial from '@/components/management/material/ViewMaterial.vue';
//新增物资购买链接
import AddMaterialLink from '@/components/management/material/AddMaterialLink.vue';
//重新绑定 二维码
import BindingAccount from '@/components/system/BindingAccount.vue';
// 立即购买 余额充足 不足
import Pay from '@/components/gallerymall/pay.vue';
// 立即购买 微信支付宝支付
import Pays from '@/components/gallerymall/pay2.vue';
// 立即购买 购买成功 失败
import PayTip from '@/components/gallerymall/paytip.vue';
// 充值
import Recharge from '@/components/gallerymall/recharge.vue';
// 确认充值
import ConfirmRecharge from '@/components/gallerymall/ConfirmRecharge.vue';
// 查看道具链接
import PropLink from '@/components/gallerymall/proplink.vue';
// 发布标准片
import Release from '@/components/gallerymall/Release.vue';
// 预览
import ImgPreview from '@/components/gallerymall/ImgPreview.vue';
// 查看跟踪记录
import ViewBrowsinghistory from '@/components/pinxuan/ViewBrowsinghistory.vue';
// 查看浏览记录
import ViewTrackrecord from '@/components/pinxuan/ViewTrackrecord.vue';
// 编辑分类
import EditClassification from '@/components/pinxuan/EditClassification.vue';
// 一级分类 二级分类
import FirstlevelClassification from '@/components/pinxuan/FirstlevelClassification.vue';
// 引用标准片
import ReferenceStandard from '@/components/pinxuan/ReferenceStandard.vue';
// 添加主题 衣码
import Addthemesize from '@/components/pinxuan/Addthemesize.vue';
// 关联展示图
import RelatedSamples from '@/components/pinxuan/RelatedSamples.vue';
//新增类型(一级)
import ModelLabel from '@/components/system/ModelLabel.vue';
// 账号设置
import AccountSettings from '@/components/AccountSettings.vue';
// 选衣二维码
import ClothseleQrcode from '@/components/management/ClothseleQrcode.vue';
// 更换员工
import ReplacingEmployees from '@/components/management/ReplacingEmployees.vue';

//数组存放接收的组件名
const components = [
  { name: "Capture", con: Capture },
  { name: "MaskPack", con: MaskPack },
  { name: "FailureReason", con: FailureReason },
  { name: "BatchImport", con: BatchImport },
  { name: "Meservation", con: Meservation },
  { name: "AddAuth", con: AddAuth },
  { name: "AddAccount", con: AddAccount },
  { name: "AddParameters", con: AddParameters },
  { name: "ViewSupplies", con: ViewSupplies },
  { name: "EditCustomer", con: EditCustomer },
  { name: "AddNotice", con: AddNotice },
  { name: "AddTheme", con: AddTheme },
  { name: "AddAdvertisement", con: AddAdvertisement },
  { name: "ViewAttachments", con: ViewAttachments },
  { name: "ViewAttachmentst", con: ViewAttachmentst },
  { name: "ProcessingProgress", con: ProcessingProgress },
  { name: "AddModelManagment", con: AddModelManagment },
  { name: "TipModel", con: TipModel },
  { name: "FileModel", con: FileModel },
  { name: "ViewMaterial", con: ViewMaterial },
  { name: "AddMaterialLink", con: AddMaterialLink },
  { name: "Tag", con: Tag },
  { name: "BindingAccount", con: BindingAccount },
  { name: "Pay", con: Pay },
  { name: "Pays", con: Pays },
  { name: "PayTip", con: PayTip },
  { name: "Recharge", con: Recharge },
  { name: "ConfirmRecharge", con: ConfirmRecharge },
  { name: "PropLink", con: PropLink },
  { name: "Release", con: Release },
  { name: "ImgPreview", con: ImgPreview },
  { name: "ViewBrowsinghistory", con: ViewBrowsinghistory },
  { name: "ViewTrackrecord", con: ViewTrackrecord },
  { name: "EditClassification", con: EditClassification },
  { name: "FirstlevelClassification", con: FirstlevelClassification },
  { name: "ReferenceStandard", con: ReferenceStandard },
  { name: "Addthemesize", con: Addthemesize },
  { name: "RelatedSamples", con: RelatedSamples },
  { name: "ModelLabel", con: ModelLabel },
  { name: "AccountSettings", con: AccountSettings },
  { name: "ClothseleQrcode", con: ClothseleQrcode },
  { name: "ReplacingEmployees", con: ReplacingEmployees },
]
//循环注册组件
const install = function (Vue, opts = {}) {
  components.forEach(component => {
    Vue.component(component.name, component.con);
  });
};

export default {
  install
};
