<template>
  <div class="order-mask" v-if="dataVal.flag">
    <!-- 添加订单 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div class="mask-title">
          <div></div>
          {{ edit == "0" ? "添加" : "编辑" }}{{ title }}
        </div>
        <i class="el-icon-close" @click="handleRefund"></i>
      </div>
      <div class="table">
        <table v-if="type == 1 || type == 2">
          <tr v-if="type == 1">
            <td class="table-td xin2">拍摄类型</td>
            <td colspan="3">
              <input v-model="users.name" placeholder="请输入一级分类拍摄类型，限4字；例如：儿童照" maxlength="4" />
            </td>
          </tr>
          <tr v-if="type == 2">
            <td class="table-td xin2">拍摄风格</td>
            <td colspan="3">
              <input v-model="users.name" placeholder="请输入二级分类拍摄风格，限4字" maxlength="4" />
            </td>
          </tr>
          <tr v-if="type == 1">
            <td class="table-td">客户填写内容</td>
            <td colspan="3" class="group">
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="性别"></el-checkbox>
                <el-checkbox label="拍摄尺码"></el-checkbox>
              </el-checkbox-group>
            </td>
          </tr>
          <tr v-if="type == 2">
            <td class="table-td xin2">排序序号</td>
            <td colspan="3">
              <input v-model="users.orderlist" placeholder="请输入排序序号" type="number" />
            </td>
          </tr>
        </table>
        <div class="tips" v-if="type == 2" style="margin-bottom: 1vh">
          包含风格
        </div>
        <div v-if="type == 2" style="margin-bottom: 3vh">
          <el-cascader :options="options" :props="{ multiple: true, checkStrictly: true, value: 'id', label: 'name' }"
            v-model="optionscascader" clearable></el-cascader>
        </div>
        <div class="inp3" v-if="type == 3" style="margin-bottom: 3vh">
          <input v-model="users.name" placeholder="请输入三级分类名称" />
        </div>
      </div>
      <div class="mask-footer">
        <button v-if="dataVal.btnq" @click="handleRefund">
          {{ dataVal.btnq }}
        </button>
        <button v-if="dataVal.btns" class="btn-bg" @click="handleSubmit">
          {{ dataVal.btns }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModelLabel",
  props: ["dataVal"],
  data() {
    return {
      title: "",
      edit: "0", // 0添加 1修改
      users: {
        type: '',
        name: "",
        pid: '',
        orderlist: "",
        containids: '',
        id: '',
        setinfo: '',
      },
      type: 1,
      checkList: [],
      options: [],
      optionscascader: [],
    };
  },
  mounted() { },
  methods: {
    //取消弹窗
    handleRefund() {
      let data = {
        flag: false,
        submitFlag: false,
      };
      this.$emit("ModelLabel", data);
    },
    handleSubmit() {
      let data = {
        flag: false, //弹窗是否显示
        submitFlag: true, //是否调用接口
        data: this.users
      };
      this.$emit("ModelLabel", data);
    },
    async typeGetchild(pid, id) {
      let data = {
        pid: pid,
        nowid: id
      }
      let res = await this.$api.typeGetchild(data)
      if (res.code == 1) {
        this.options = res.data
      }
    },
    async typeDetail() {
      let res = await this.$api.typeDetail({
        id: this.users.id
      })
      if (res.code == 1) {
        this.users = {
          type: this.type,
          name: res.data.name,
          pid: res.data.pid ? res.data.pid : '',
          orderlist: res.data.orderlist,
          containids: res.data.containids,
          id: res.data.id,
          setinfo: res.data.setinfo,
        }
        let checkListarr = res.data.setinfo ? res.data.setinfo.split(',') : []
        this.checkList = []
        checkListarr.forEach(e => {
          if (e == '1') {
            this.checkList.push('性别')
          }
          if (e == '3') {
            this.checkList.push('拍摄尺码')
          }
        })
        this.optionscascader = res.data.containids ? res.data.containids.split(',').map(Number) : []
      }
    }
  },
  watch: {
    dataVal: {
      handler(newVal, oldVal) {
        if (newVal.flag) {
          this.type = newVal.type;
          this.title = newVal.title;
          this.edit = newVal.edit;
          this.users.type = newVal.type

          if (newVal.edit == 0) { //增加
            this.users = {
              type: newVal.type,
              name: "",
              pid: newVal.data.id ? newVal.data.id : '',
              orderlist: "",
              containids: '',
              id: newVal.data.id,
              setinfo: '',
            }
            this.checkList = []
            this.optionscascader = []
          }
          if (newVal.edit == 0 && newVal.edittype == 2) {
            this.typeGetchild(newVal.data.id, '')
          } else if (newVal.edit == 1 && newVal.edittype == 2) {
            this.typeGetchild(newVal.data.pid, newVal.data.id)
          }
          if (newVal.edit == "1") {//修改
            this.users.id = newVal.data.id
            this.typeDetail()
          }
        }
      },
      deep: true,
    },
    checkList: {
      handler(newVal, oldVal) {
        let arr = []
        newVal.forEach(item => {
          if (item == '性别') {
            arr.push('1')
          }
          if (item == '拍摄尺码') {
            arr.push('3')
          }
        });
        this.users.setinfo = arr.join(',')
      },
      deep: true,
    },
    optionscascader: {
      handler(newVal, oldVal) {
        this.users.containids = newVal.flat().join(',')
      },
      deep: true,
    }
  },
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.table table {
  width: 32.969vw;
  margin-top: 1.852vh;
}

.table table input {
  padding-left: 1.25vw;
  font-size: 0.833vw;
  width: 88%;
}

.group {
  padding-left: 2vw;
}

.tips {
  font-size: 0.833vw;
  font-weight: bold;
}

/deep/.el-cascader {
  width: 100%;
}

/deep/.el-cascader-menu {
  min-width: 30vw !important;
}

.inp3 {
  width: 100%;
  margin-top: 1.852vh;

  input {
    width: 100%;
    border: 0.052vw solid #e1e1e1;
    line-height: 4.815vh;
    padding-left: 1.25vw;
    font-size: 0.833vw;
    outline: none;
    box-sizing: border-box;
  }
}

.order-mask {
  .mask-main {
    width: 30vw;
    border-radius: 1.104vh 0.52vw 0.7886vh 0.52vw;
  }

  .ms {
    min-height: 6.309vh;
  }

  .mask-content {
    display: flex;
    align-items: flex-start;
    font-size: 0.83vw;
    color: #333333;

    margin: 2.05vh 0 1.577vh;

    i {
      font-size: 1.25vw;
      color: #2c72ff;
      margin-right: 0.521vw;
    }
  }

  .mask-content-mt {
    margin-bottom: 1vh;
  }

  .mask-footer {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 7.81vw;
      height: 3.627vh;
      border-radius: 0.31vw;
      font-size: 0.73vw;
      cursor: pointer;
    }

    button {
      background: #fff;
      border: 0.0789vh solid #2c72ff;
      color: #2c72ff;
      margin-right: 3.13vw;
    }

    button:last-child {
      margin-right: 0;
    }

    .btn-bg {
      background: #2c72ff;
      border: 0.0789vh solid #2c72ff;
      color: #fff;
    }
  }
}
</style>