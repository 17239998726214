<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 购买 -->
        <div class="mask-main">
            <div class="mask-cen">
                <div class="mask_title">
                    <div class="t1"></div>
                    <div class="t2">立即购买</div>
                    <div class="t3" @click="handleRefund">×</div>
                </div>
                <div class="xian"></div>
                <div class="mask-content">
                    <div class="mask-con_h">
                        <em class="c1">¥ &nbsp;</em>
                        <em class="c2">{{ firstamount }}.</em>
                        <em class="c3">{{ endamount }}</em>
                    </div>
                    <div class="c4">账户余额：{{ balance }}元</div>
                </div>
                <div class="mask-footer" v-if="Number(balance) < Number(price)" @click="handleSubmit('1')">去充值</div>
                <div class="mask-footer" v-if="Number(balance) >= Number(price)" @click="handleSubmit('2')">确认购买</div>
            </div>
        </div>
    </div>
</template>
    
<script>
export default {
    name: "paytip",
    props: ['data'],
    data() {
        return {
            shopinfo: {},//商品信息
            price: null,//价格
            firstamount: null,//价格小数点前
            endamount: null,//价格小数点后
            balance: null,//余额
            // countdown: 3,
        };
    },
    mounted() {
        this.indexGetinfo()
    },
    methods: {
        // 当前账号信息
        async indexGetinfo() {
            let res = await this.$api.indexGetinfo()
            if (res.code == 1) {
                this.balance = res.data.balance//账户余额
                // console.log(res.data.profitmoney);//可提现金额
            }
        },
        //取消弹窗
        handleRefund() {
            let data = {
                flag: false,//弹窗是否显示
                submitFlag: false//是否调用接口
            }
            this.$emit("payCall", data);
        },
        handleSubmit(type) {
            let data = {
                flag: false,//弹窗是否显示
                submitFlag: true,//是否调用接口
                buytype: type// '1':充值 '2':购买
            }
            this.$emit("payCall", data);
        },
    },
    watch: {
        data: {
            deep: true,
            handler(newVal, oldVal) {
                this.shopinfo = JSON.parse(JSON.stringify(newVal.commodity))
                this.price = newVal.commodity.price

                if (newVal.commodity.price % 1 === 0) {
                    this.firstamount = newVal.commodity.price.indexOf(".") == -1 ? newVal.commodity.price : newVal.commodity.price.slice(0, newVal.commodity.price.indexOf("."))
                    this.endamount = '00'
                } else {
                    this.firstamount = newVal.commodity.price.slice(0, newVal.commodity.price.indexOf("."))
                    this.endamount = newVal.commodity.price.slice(newVal.commodity.price.indexOf(".") + 1, newVal.commodity.price.length)
                }

            }
        }
    }
};
</script>
    
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask {
    .mask-main {
        width: 31.25vw;
        padding: 0;
        border-radius: 0.729vw 0.521vw 0.521vw 0.521vw;

        .mask-cen {
            margin: 2.397vh 1.563vw 3.471vh;
            width: 28.125vw;
            display: flex;
            flex-direction: column;
            align-items: center;

            .mask_title {
                width: 100%;
                // height: 3.471vh;
                display: flex;
                justify-content: space-between;
                align-items: center;

                // .t1 {}

                .t2 {
                    font-weight: bold;
                    font-family: MicrosoftYaHei-Bold;
                    font-size: 0.885vw;
                    color: #333333;
                }

                .t3 {
                    font-family: MicrosoftYaHeiLight;
                    font-size: 1.875vw;
                    color: #333333;
                    cursor: pointer;
                }
            }

            .xian {
                width: 28.125vw;
                height: 0.052vw;
                background-color: #e1e1e1;
                border-radius: 0.03vw 0.026vw 0.026vw 0.022vw;
            }

            .mask-content {
                margin-top: 5.041vh;
                display: flex;
                flex-direction: column;
                align-items: center;

                .mask-con_h {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: baseline;

                    .c1 {
                        text-align: bottom;
                        font-family: SourceHanSansCN-Medium;
                        font-size: 2.292vw;
                        color: #ff0000;
                        font-style: normal;
                    }

                    .c2 {
                        text-align: bottom;
                        font-family: SourceHanSansCN-Medium;
                        font-size: 3.646vw;
                        color: #ff0000;
                        font-style: normal;
                    }

                    .c3 {
                        text-align: bottom;
                        font-family: SourceHanSansCN-Medium;
                        font-size: 2.396vw;
                        color: #ff0000;
                        font-style: normal;
                    }
                }

                .c4 {
                    font-family: MicrosoftYaHei;
                    font-size: 0.938vw;
                    color: #666666;
                    margin-top: 3.471vh;
                }
            }

            .mask-footer {
                width: 9.896vw;
                height: 2.396vw;
                line-height: 2.396vw;
                text-align: center;
                background-color: #2c72ff;
                border-radius: 0.521vw;
                margin-top: 5.537vh;
                font-family: MicrosoftYaHei;
                font-size: 0.885vw;
                color: #ffffff;
                cursor: pointer;
            }
        }
    }

}
</style>