<template>
    <!-- 拍摄完成/拍摄未完成 -->
    <div v-if="data.flag" class="mask">
        <div class="mask-main">
            <div class="mask-header">
                <div class="mask-left">
                    {{ data.title }}
                </div>
            </div>
            <div class="mask-line"></div>
            <div class="mask-table">
                <div class="btn-foot" v-if="data.type == 'see'">
                    <button class="btn-color" @click="handleSupplies('add')">关联物资</button>
                    <button @click="handleRemove">批量移除</button>
                </div>
                <div v-if="data.type == 'add'" class="search_nav">
                    <!-- 搜索框 -->
                    <el-input v-model="keyword" class="search_inp" style="width: 13.542vw;"
                        placeholder="物资ID/物资名称/创建人"></el-input>
                    <!-- 衣码下拉框 -->
                    <el-select :popper-append-to-body="false" v-model="locator_id" placeholder="请选择库位编号"
                        class="clothing_size" clearable>
                        <el-option v-for="item in locator_size" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                    <!-- 请选择评价状态 -->
                    <el-select :popper-append-to-body="false" v-model="cate" placeholder="请选择物资分类" class="evaluation_status"
                        clearable>
                        <el-option v-for="item in evaluation_status" :key="item.values" :label="item.values"
                            :value="item.values">
                        </el-option>
                    </el-select>
                    <div class="block" style="margin-right: 0.521vw;">
                        <el-date-picker v-model="timelist" type="daterange" range-separator="-" start-placeholder="创建时间开始"
                            end-placeholder="创建时间结束">
                        </el-date-picker>
                    </div>
                    <!-- 查询按钮 -->
                    <el-button class="searchBtn" type="primary" @click="materialsLst('search')">查询</el-button>
                </div>
                <el-table id="filei" class="center_table" height="45vh" max-height="500" ref="multipleTable"
                    :data="tableDatalist" tooltip-effect="dark" style="width: 100%" border :header-cell-style="{
                        width: 81.823 + 'vw',
                        height: 5.185 + 'vh',
                        background: '#F9F9F9',
                        fontSize: 0.833 + 'vw',
                        textAlign: 'center',
                    }" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" min-width="4.8px" align="center">
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="物资名称" min-width="14.8px">
                        <template slot-scope="scope">
                            <span class="aFu_span">{{ scope.row.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="locator_id" align="center" label="库位编号" show-overflow-tooltip
                        min-width="16.0px"></el-table-column>
                    <el-table-column align="center" prop="counts" label="数量" show-overflow-tooltip min-width="9.0px">
                    </el-table-column>
                    <el-table-column align="center" prop="images" label="物资实物" show-overflow-tooltip min-width="20.9px">
                        <template slot-scope="scope">
                            <div class="aFu" @contextmenu="disableRightClick">
                                <el-image
                                    style="max-width: 4.167vw !important;max-height: 3.704vh !important;width: auto !important;height: auto !important;"
                                    :src="imgurl + scope.row.images" @click="bigimgclick(imgurl + scope.row.images)">
                                </el-image>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="cate" label="物资分类" show-overflow-tooltip
                        min-width="11.9px"></el-table-column>
                    <el-table-column align="center" prop="sex" label="适用性别" show-overflow-tooltip
                        min-width="11.9px"></el-table-column>
                    <!-- <el-table-column align="center" prop="zt2" label="关联记录" show-overflow-tooltip min-width="12.2px">
                        <template slot-scope="scope">
                            <span class="aFu_span">查看</span>
                        </template></el-table-column> -->
                    <el-table-column align="center" prop="createtime" label="创建时间" show-overflow-tooltip
                        min-width="27.9px"></el-table-column>
                    <el-table-column label="创建人" prop="adminname" align="center" min-width="12.0px">
                    </el-table-column>
                </el-table>
            </div>
            <div class="mask-foot">
                <button @click="handlesave()" v-if="data.type == 'see'" class="btn-color">保存</button>
                <button @click="handleconfirm(multipleSelection)" v-else class="btn-color">确认关联</button>
                <button @click="handleClose()">取消</button>
            </div>
            <MaskPack :data="pushObj" @childRef="handlePushsCall"></MaskPack>
            <!-- 预览 -->
            <ImgPreview :data="imgpreviewObj" @imgpreviewCall="imgpreviewCall"></ImgPreview>
        </div>
    </div>
</template>

<script>
export default {
    name: "capture",
    props: ['data'],
    data() {
        return {
            keyword: "",
            locator_id: "",
            cate: "",
            timelist: null,
            tableDatalist: [],
            importArr: [],
            evaluation_status: [],
            multipleSelection: [],
            multipleSelectionarr: [],
            locator_size: [],

            pushObj: {
                flag: false,//是否显示
                title: "移除物资",//标题
                text: "确认要批量移除物资吗？",//内容
                btns: "确定",//确定按钮
                btnq: "关闭",//取消按钮
            },
            shopname: '',
            imgpreviewObj: {
                flag: false,
                src: '',
                shopname: '',
            }

        }
    },
    mounted() { },
    methods: {
        //得到当前账号所关联的店铺信息
        async indexGetinfo() {
            let res = await this.$api.indexGetinfo();
            if (res.code == 1) {
                //  console.log(res.data);
                this.shopname = res.data.shopname
            }
        },
        // 阻止默认右键菜单的显示
        disableRightClick(event) {
            event.preventDefault();
            return false
        },
        bigimgclick(url) {
            this.imgpreviewObj.flag = true
            this.imgpreviewObj.src = url
            this.imgpreviewObj.shopname = this.shopname
        },
        imgpreviewCall() {
            this.imgpreviewObj.flag = false
        },
        // 打开关联物资
        handleSupplies(type) {
            this.data.type = type;
            // this.materialsLst()
        },

        //关闭弹窗
        handleClose() {
            // 查看
            if (this.data.id) {
                if (this.data.type == "see") {
                    this.$emit('handleSuppliesCell', { flag: false, submitFlag: false })
                } else {
                    this.data.type = "see"
                }
                // 编辑
            } else if (this.$route.query.id) {
                this.$emit('handleSuppliesCell', { flag: false, submitFlag: false })
                // 新增
            } else {
                this.$emit('handleSuppliesCell', { flag: false, submitFlag: false })
            }

        },
        // 点击保存
        handlesave() {
            if (this.data.id) {
                let obj = {
                    flag: false,
                    submitFlag: true,
                    ids: this.multipleSelection
                }
                this.$emit('handleSuppliesCell', obj)
            } else {
                let ids = []
                this.tableDatalist.forEach(element => {
                    ids.push(element.id)
                });
                ids = JSON.parse(JSON.stringify(ids)) + ""
                let obj = {
                    flag: false,
                    submitFlag: true,
                    ids: ids
                }
                this.$emit('handleSuppliesCell', obj)
            }
        },
        // 确认关联物资
        // async handleconfirm() {
        //     if (this.data.id) {
        //         let data = {
        //             materials_ids: this.multipleSelection,
        //             standard_ids: this.data.id,
        //         }
        //         let res = await this.$api.standardAssociation(data)
        //         if (res.code == 1) {
        //             this.standardDetail()
        //             this.data.type = "see"
        //         }
        //     } else {
        //         this.data.type = "see"
        //         this.tableDatalist = this.multipleSelectionarr
        //         // console.log(this.importArr);
        //     }
        // },
        async handleconfirm() {
            if (this.data.id) {
                let data = {
                    materials_ids: this.multipleSelection,
                    standard_ids: this.data.id,
                }
                let res = await this.$api.standardAssociation(data)
                if (res.code == 1) {
                    this.standardDetail()
                    this.data.type = "see"
                }
            } else if (this.$route.query.id) {
                let obj = {
                    flag: false,
                    submitFlag: true,
                    ids: this.multipleSelection
                }
                this.$emit('handleSuppliesCell', obj)

            } else {
                let obj = {
                    flag: false,
                    submitFlag: true,
                    ids: this.multipleSelection
                }
                this.$emit('handleSuppliesCell', obj)
            }
        },
        //移除关联
        handleRemove() {
            this.pushObj.flag = true
        },
        //移除关联回调
        async handlePushsCall(obj) {
            if (obj.submitFlag) {
                if (this.data.id) {
                    let data = {
                        materials_ids: this.multipleSelection,
                        standard_ids: this.data.id,
                    }
                    let res = await this.$api.standardCancelassociation(data)
                    if (res.code == 1) {
                        this.pushObj.flag = false
                        this.standardDetail()
                    }
                } else {
                    this.multipleSelectionarr.forEach(element => {
                        this.tableDatalist = this.tableDatalist.filter((item) => {
                            return item.id != element.id
                        })
                    })
                    this.pushObj.flag = false
                }
            } else {
                this.pushObj.flag = false
            }

        },
        handleSelectionChange(val) {
            this.multipleSelectionarr = val
            this.multipleSelection = []
            val.forEach(element => {
                this.multipleSelection.push(element.id)
            });
            this.multipleSelection = JSON.parse(JSON.stringify(this.multipleSelection)) + ""
            //  console.log(this.multipleSelection)
        },
        //库位编号
        async locatorLst() {
            let res = await this.$api.locatorLst()
            if (res.code == 1) {
                this.locator_size = []
                res.data.forEach(element => {
                    element.sec.forEach(item => {
                        item.name = element.name + " - " + item.name
                        this.locator_size.push(item)
                    })
                })
            }
        },
        //物资分类
        async materialsGetcate() {
            let res = await this.$api.materialsGetcate()
            if (res.code == 1) {
                this.evaluation_status = res.data
            }
        },
        async materialsLst(type) {
            let data = {
                status: 1,
                keyword: this.keyword,
                locator_id: this.locator_id,
                cate: this.cate,
                stime: this.timelist != null ? Date.parse(this.timelist[0]) / 1000 : "",
                etime: this.timelist != null ? Date.parse(this.timelist[1]) / 1000 : "",
            }
            let res = await this.$api.materialsLst(data)
            if (res.code == 1) {
                res.data.list.forEach(element => {

                    if (element.images.replace(/\//g, "/").split(',')[0].charAt(0) != "/") {
                        element.images = "/" + element.images.replace(/\//g, "/").split(',')[0]
                    } else {
                        element.images = element.images.replace(/\//g, "/").split(',')[0]
                    }
                    element.createtime = this.$FilDate.filDate(element.createtime)
                    this.locator_size.forEach(item => {
                        if (element.locator_id == item.id) {
                            element.locator_id = item.name
                        }
                    })
                });
                this.tableDatalist = res.data.list
                if (type == 'search') {
                    this.keyword = ""
                    this.locator_id = ""
                    this.cate = ""
                    this.timelist = null
                }
            }
        },
        async standardDetail() {
            let data = {
                id: this.data.id,
            }
            let res = await this.$api.standardDetail(data)
            if (res.code == 1) {
                res.data.materials.forEach(element => {
                    element.createtime = this.$FilDate.filDate(element.createtime * 1000)
                    element.updatetime = this.$FilDate.filDate(element.updatetime * 1000)
                    this.locator_size.forEach(item => {
                        if (element.locator_id == item.id) {
                            element.locator_id = item.name
                        }
                    })
                    element.images = element.images.split(',')[0]
                    if (element.images.replace(/\//g, "/").split(',')[0].charAt(0) != "/") {
                        element.images = "/" + element.images.replace(/\//g, "/").split(',')[0]
                    } else {
                        element.images = element.images.replace(/\//g, "/").split(',')[0]
                    }

                });
                this.tableDatalist = res.data.materials
            }
        }
    },
    created() {
        this.locatorLst()
        this.materialsGetcate()

        this.indexGetinfo()
        // if (this.data.id) {
        //     this.standardDetail()
        // }
    },
    watch: {
        data: {
            deep: true,
            handler(newvalue, oldValue) {
                if (newvalue.id) {
                    this.standardDetail()
                }
                if (newvalue.type == "add") {
                    this.materialsLst()
                }
            }
        }
    }

}
</script>

<style lang="less" scoped>
@import url("@/assets/css/maskCap.less");
@import url("@/assets/css/reservation/telement.less");

/deep/.el-table__body-wrapper {
    max-height: 48vh !important;
}

.mask-foot {
    position: absolute;
    left: 50%;
    bottom: 3vw !important;
}

.filei .btn-foot {
    justify-content: flex-end;
    margin-top: 0;
    margin-bottom: 2.222vh;
}

.search_nav {
    padding: 0 0 2.222vh;
}

.search_nav .search_inp {
    margin-left: 0;
}

.btn-foot {
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
    margin-bottom: 2.222vh;

    button {
        width: 6.25vw;
        height: 4.259vh;
        background-color: #ffffff;
        border-radius: 0.521vw;
        border: solid 0.052vw #2c72ff;
        color: #2c72ff;
        margin-right: 1.563vw;
        cursor: pointer;
    }

    button:last-child {
        margin-right: 0;
    }

    .btn-color {
        background-color: #2c72ff;
        color: #fff;
    }
}

/deep/.el-image__inner {
    width: auto !important;
    height: auto !important;
    max-width: 4.167vw !important;
    max-height: 3.704vh !important;
}

.clothing_size {
    border: 0.052vw solid #DCDFE6;

    /deep/.el-select-dropdown {
        top: 21.778vh !important;
    }
}

.evaluation_status {
    border: 0.052vw solid #DCDFE6;

    /deep/.el-select-dropdown {
        top: 21.778vh !important;
    }
}

/deep/.el-input__inner {
    border: 0.052vw solid #DCDFE6 !important;
}

/deep/.el-date-editor .el-range-separator {
    padding: 0 0.26vw;
    line-height: 2.963vh;
    font-size: 0.729vw;
}

.aFu {
    /deep/img {
        max-width: 4.167vw;
        max-height: 3.704vh;
    }
}
</style>