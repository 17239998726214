<template>
    <div class="order-mask" v-if="dataVal.flag">
        <!-- 添加订单 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div></div>
                <div class="mask-title">
                    {{ dataVal.title }}
                </div>
                <i class="el-icon-close" @click="handleRefund"></i>
            </div>
            <div class="mask-content">
                <el-checkbox-group v-if="dataVal.title == '主题'" v-model="checkList">
                    <el-checkbox v-for="item in themelist" :key="item.id" :label="item.name"></el-checkbox>
                </el-checkbox-group>
                <el-checkbox-group v-if="dataVal.title == '衣码'" v-model="checkList">
                    <el-checkbox v-for="item in sizelist" :key="item.values" :label="item.values"></el-checkbox>
                </el-checkbox-group>
            </div>
            <div class="mask-footer">
                <button class="btn-bg" @click="handleSubmit">保存</button>
            </div>
        </div>
    </div>
</template>
    
<script>
export default {
    name: "Addthemesize",
    props: ['dataVal'],
    data() {
        return {
            type: '',
            sizelist: [],
            themelist: [],
            checkList: []
        };
    },
    mounted() {
    },
    methods: {
        //取消弹窗
        handleRefund() {
            let data = {
                flag: false,
                submitFlag: false,
            }
            this.$emit("themesizeCall", data);
        },
        handleSubmit() {
            if (this.checkList.length == 0) {
                let data = {
                    flag: false,
                    submitFlag: true,
                    themesizeids: [],
                }
                this.$emit("themesizeCall", data);
            } else {
                let arr = []
                if (this.type == 'theme') {
                    this.themelist.forEach(item => {
                        this.checkList.forEach(element => {
                            if (element == item.name) {
                                arr.push(item.id)
                            }
                        })
                    })

                } else if (this.type == 'size') {
                    arr = this.checkList
                }

                let data = {
                    flag: false,//弹窗是否显示
                    submitFlag: true,//是否调用接口
                    themesizeids: arr,
                    themesizelength: 0,
                }
                data.themesizelength = data.themesizeids.length
                this.$emit("themesizeCall", data);
            }
        },
        //衣码
        async standardGetsize() {
            let res = await this.$api.standardGetsize()
            if (res.code == 1) {
                this.sizelist = res.data
            }
        },
        async standardGettheme() {
            let res = await this.$api.standardGettheme()
            if (res.code == 1) {
                this.themelist = res.data
            }
        },
    },
    watch: {
        dataVal(newVal, oldVal) {
            this.checkList = newVal.themesizeids
            if (newVal.title == '主题') {
                this.type = 'theme'
                this.standardGettheme()
            } else if (newVal.title == '衣码') {
                this.type = 'size'
                this.standardGetsize()
            }
        }
    }
};
</script>
    
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask {
    .mask-main {
        width: 37.709vw;
        border-radius: 1.296vh 0.521vw 0.926vh 0.521vw;
        background-color: #f7f7f7;
        padding: 0 1.0417vw;

        .mask-nav {
            padding: 2.13vh 0;
            border-bottom: 0.185vh solid #d1d1d1;
        }

        .mask-content {
            width: 100%;
            height: 30vh !important;
            display: flex;
            align-items: flex-start;
            font-size: 0.833vw;
            color: #333333;
            height: 7.407vh;
            margin: 2.407vh 0 1.852vh;

            /deep/.el-checkbox__inner {
                border: .0521vw solid #DCDFE6;
            }
        }

        .mask-footer {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 1.042vw;

            button {
                width: 7.813vw;
                height: 4.259vh;
                border-radius: 0.313vw;
                font-size: 0.729vw;
                background: #fff;
                border: 0.052vw solid#066ff8;
                color: #066FF8;
                cursor: pointer;
            }

            .btn-bg {
                background: #066ff8;
                border: 0.052vw solid#066ff8;
                color: #fff;
            }
        }
    }
}
</style>