<template>
    <!-- 拍摄完成/拍摄未完成 -->
    <div v-if="data.flag" class="mask">
        <div class="mask-main">
            <div class="mask-header">
                <div class="mask-left">
                    标准片列表
                </div>
            </div>
            <div class="mask-line"></div>
            <div class="mask-table">
                <div class="btn-foot" v-if="data.type == 'see'">
                    <button class="btn-color" @click="handleSupplies('add')">关联标准片</button>
                    <button @click="handleRemove()">批量移除</button>
                </div>
                <div v-if="data.type == 'add'" class="search_nav">
                    <!-- 搜索框 -->
                    <el-input v-model="keyword" class="search_inp" style="width: 13.542vw;" placeholder="标准片ID"></el-input>
                    <!-- 衣码下拉框 -->
                    <el-select :popper-append-to-body="false" v-model="theme_id" placeholder="请选择拍摄主题" class="clothing_size"
                        clearable>
                        <el-option v-for="item in theme_type" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                    <div class="yards">
                        <el-input v-model="min_size" style="width: 4.167vw;" placeholder="衣码最小码"></el-input>
                        <span>-</span>
                        <el-input v-model="max_size" style="width: 4.167vw;" placeholder="衣码最大码"></el-input>
                    </div>
                    <!-- 请选择标准片来源 -->
                    <el-select :popper-append-to-body="false" v-model="source" placeholder="请选择标准片来源"
                        class="negative_upload" clearable>
                        <el-option v-for="item in source_type" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <!-- 查询按钮 -->
                    <el-button class="searchBtn" type="primary" @click="standardLst('search')">查询</el-button>
                </div>
                <el-table id="filei" class="center_table" ref="multipleTable" height="45vh" :data="importArr"
                    tooltip-effect="dark" style="width: 100%" border :header-cell-style="{
                        width: 81.823 + 'vw',
                        height: 5.185 + 'vh',
                        background: '#F9F9F9',
                        fontSize: 0.833 + 'vw',
                        textAlign: 'center',
                    }" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" min-width="4.8px" align="center">
                    </el-table-column>
                    <el-table-column align="center" prop="id" label="标准图库ID" min-width="12.6px">
                        <template slot-scope="scope">
                            <span class="aFu_span">{{ scope.row.id }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" align="center" label="标准片名称" show-overflow-tooltip
                        min-width="16.5px"></el-table-column>
                    <el-table-column align="center" prop="images" label="客户可见标准片" show-overflow-tooltip min-width="29.6px">
                        <template slot-scope="scope">
                            <div class="aFu" @contextmenu="disableRightClick">
                                <!-- <img style="max-width: 100%;max-height: 3.704vh;" v-for="item in scope.row.images" :key="item"
                                    :src="imgurl + item"> -->
                                <el-image style="max-width: 4.167vw;max-height: 3.704vh;" :src="imgurl + scope.row.image"
                                    @click="bigimgclick(imgurl + scope.row.image)">
                                </el-image>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="theme_ids" label="拍摄主题" show-overflow-tooltip
                        min-width="14.7px"></el-table-column>
                    <el-table-column align="center" prop="age" label="适用年龄" show-overflow-tooltip
                        min-width="10.3px"></el-table-column>
                    <el-table-column align="center" prop="min_size" label="衣码" show-overflow-tooltip
                        min-width="11.7px"></el-table-column>
                    <el-table-column label="标准片来源" prop="source" align="center" min-width="12.0px">
                    </el-table-column>
                </el-table>
            </div>
            <div class="mask-foot">
                <button @click="handlesave()" v-if="data.type == 'see'" class="btn-color">保存</button>
                <button @click="handleconfirm(multipleSelection)" v-else class="btn-color">确认关联</button>
                <button @click="handleClose()">取消</button>
            </div>
            <MaskPack :data="pushObj" @childRef="handlePushsCall"></MaskPack>
            <!-- 预览 -->
            <ImgPreview :data="imgpreviewObj" @imgpreviewCall="imgpreviewCall"></ImgPreview>
        </div>
    </div>
</template>

<script>
export default {
    name: "capture",
    props: ['data'],
    data() {
        return {
            keyword: "",
            theme_id: "",
            min_size: '',
            max_size: "",
            source: "",
            timelist: null,
            theme_type: [],
            importArr: [],
            multipleSelection: [],
            multipleSelectionarr: [],

            source_type: [
                {
                    value: 0, //id
                    label: "录入"
                },
                {
                    value: 1, //id
                    label: "购买"
                }
            ],
            pushObj: {
                flag: false,//是否显示
                title: "移除标准片",//标题
                text: "确认要批量移除标准片吗？",//内容
                btns: "确定",//确定按钮
                btnq: "关闭",//取消按钮
            },

            shopname: '',
            imgpreviewObj: {
                flag: false,
                src: '',
                shopname: '',
            }
        }
    },
    mounted() {
    },
    methods: {
        //得到当前账号所关联的店铺信息
        async indexGetinfo() {
            let res = await this.$api.indexGetinfo();
            if (res.code == 1) {
                //  console.log(res.data);
                this.shopname = res.data.shopname
            }
        },
        // 阻止默认右键菜单的显示
        disableRightClick(event) {
            event.preventDefault();
            return false
        },
        bigimgclick(url) {
            this.imgpreviewObj.flag = true
            this.imgpreviewObj.src = url
            this.imgpreviewObj.shopname = this.shopname
        },
        imgpreviewCall() {
            this.imgpreviewObj.flag = false
        },
        handleSupplies(type) {
            this.data.type = type;
            // this.standardLst()
        },
        //关闭弹窗
        handleClose() {
            if (this.data.id) {
                if (this.data.type == "see") {
                    this.$emit('handleSuppliesCell', { flag: false, submitFlag: false })
                } else {
                    this.data.type = "see"
                }
            } else if (this.$route.query.id) {
                this.$emit('handleSuppliesCell', { flag: false, submitFlag: false })
            } else {
                this.$emit('handleSuppliesCell', { flag: false, submitFlag: false })
            }
        },
        handlesave() {
            if (this.data.id) {
                let obj = {
                    flag: false,
                    submitFlag: false,
                }
                this.$emit('handleSuppliesCell', obj)
            } else {
                let ids = []
                this.importArr.forEach(element => {
                    ids.push(element.id)
                });
                ids = JSON.parse(JSON.stringify(ids)) + ""
                let obj = {
                    flag: false,
                    submitFlag: true,
                    ids: ids
                }
                //  console.log(obj.ids);
                this.$emit('handleSuppliesCell', obj)
            }
        },
        async handleconfirm() {
            if (this.data.id) {
                let data = {
                    materials_ids: this.data.id,
                    standard_ids: this.multipleSelection,
                }
                let res = await this.$api.materialsAssociation(data)
                if (res.code == 1) {
                    this.materialsDetail()
                    this.data.type = "see"
                }
            } else if (this.$route.query.id) {

                let obj = {
                    flag: false,
                    submitFlag: true,
                    ids: this.multipleSelection
                }
                this.$emit('handleSuppliesCell', obj)
            } else {
                // this.data.type = "see"
                // this.importArr = this.multipleSelectionarr
                let obj = {
                    flag: false,
                    submitFlag: true,
                    ids: this.multipleSelection
                }
                this.$emit('handleSuppliesCell', obj)
            }
        },
        //移除关联
        handleRemove() {
            this.pushObj.flag = true
        },
        //移除关联回调
        async handlePushsCall(obj) {
            if (obj.submitFlag) {
                if (this.data.id) {
                    let data = {
                        materials_ids: this.data.id,
                        standard_ids: this.multipleSelection,
                    }
                    let res = await this.$api.materialsCancelassociation(data)
                    if (res.code == 1) {
                        this.pushObj.flag = false
                        this.materialsDetail()
                    }
                } else {
                    this.multipleSelectionarr.forEach(element => {
                        this.importArr = this.importArr.filter((item) => {
                            return item.id != element.id
                        })
                    })
                    this.pushObj.flag = false
                }
            } else {
                this.pushObj.flag = false
            }

        },
        handleSelectionChange(val) {
            this.multipleSelectionarr = val
            this.multipleSelection = []
            val.forEach(element => {
                this.multipleSelection.push(element.id)
            });
            this.multipleSelection = JSON.parse(JSON.stringify(this.multipleSelection)) + ""
            //  console.log(this.multipleSelection)
            // this.multipleSelection = val
        },
        async standardGettheme() {
            let res = await this.$api.standardGettheme()
            if (res.code == 1) {
                this.theme_type = res.data
            }
        },
        async standardLst(type) {
            let data = {
                keyword: this.keyword,
                theme_id: this.theme_id,
                min_size: this.min_size,
                max_size: this.max_size,
                source: this.source
            }
            let res = await this.$api.standardLst(data)
            if (res.code == 1) {
                res.data.list.forEach(element => {
                    if (element.image.charAt(0) != "/") {
                        element.image = "/" + element.image
                    }
                    //  console.log(element.image);
                    if (element.theme_ids.charAt(0) == ",") {
                        element.theme_ids = element.theme_ids.slice(1)
                    }
                    if (element.theme_ids.charAt(element.theme_ids.length - 1) == ",") {
                        element.theme_ids = element.theme_ids.slice(0, element.theme_ids.length - 1)
                    }
                    element.theme_ids = element.theme_ids.split(",")
                    for (let i = 0; i < element.theme_ids.length; i++) {
                        this.theme_type.forEach(item => {
                            if (item.id == element.theme_ids[i]) {
                                element.theme_ids[i] = item.name
                            }
                        })
                    }
                    element.theme_ids = JSON.parse(JSON.stringify(element.theme_ids)) + ""
                })
                //  console.log(res.data.list.image);
                this.importArr = res.data.list
                if (type == "search") {
                    this.keyword = ""
                    this.theme_id = ""
                    this.min_size = ""
                    this.max_size = ""
                    this.source = ""
                }
            }
        },
        async materialsDetail() {
            let data = {
                id: this.data.id
            }
            let res = await this.$api.materialsDetail(data)
            if (res.code == 1) {
                res.data.standard.forEach(element => {
                    if (element.image.charAt(0) != "/") {
                        element.image = "/" + element.image
                    }
                    //  console.log(element.image);
                    if (element.theme_ids.charAt(0) == ",") {
                        element.theme_ids = element.theme_ids.slice(1)
                    }
                    if (element.theme_ids.charAt(element.theme_ids.length - 1) == ",") {
                        element.theme_ids = element.theme_ids.slice(0, element.theme_ids.length - 1)
                    }
                    element.theme_ids = element.theme_ids.split(",")
                    for (let i = 0; i < element.theme_ids.length; i++) {
                        this.theme_type.forEach(item => {
                            if (item.id == element.theme_ids[i]) {
                                element.theme_ids[i] = item.name
                            }
                        })
                    }
                    element.theme_ids = JSON.parse(JSON.stringify(element.theme_ids)) + ""
                })
                this.importArr = res.data.standard
            }
        }

    },
    created() {
        this.standardGettheme()

        this.indexGetinfo()

    },
    watch: {
        data: {
            deep: true,
            handler(newvalue, oldValue) {
                if (newvalue.id) {
                    this.materialsDetail()
                }
                if (newvalue.type == "add") {
                    this.standardLst()
                }
            }
        },

    }
}
</script>

<style lang="less" scoped>
@import url("@/assets/css/maskCap.less");
@import url("@/assets/css/reservation/telement.less");

// .mask .mask-main .mask-table {
//     max-height: 50vh;
//     // overflow-y: scroll;
// }
/deep/.el-table__body-wrapper {
    max-height: 48vh;
}

.mask-foot {
    position: absolute;
    bottom: 3vw !important;
    left: 50%;
}

.filei .btn-foot {
    justify-content: flex-end;
    margin-top: 0;
    margin-bottom: 2.222vh;
}

.search_nav {
    padding: 0 0 2.222vh;
}

.search_nav .search_inp {
    margin-left: 0;
}

.btn-foot {
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
    margin-bottom: 2.222vh;

    button {
        width: 6.25vw;
        height: 4.259vh;
        background-color: #ffffff;
        border-radius: 0.521vw;
        border: solid 0.052vw #2c72ff;
        color: #2c72ff;
        margin-right: 1.563vw;
        cursor: pointer;
    }

    button:last-child {
        margin-right: 0;
    }

    .btn-color {
        background-color: #2c72ff;
        color: #fff;
    }
}

.yards {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 11.979vw;
    padding: 0 1.094vw;
    border: 0.052vw solid#DCDFE6;
    border-radius: 0.208vw;
    height: 3.75vh;
    margin-right: 0.521vw;
    box-sizing: border-box;
    margin-top: -0.052vw;

    span {
        color: #999999;
        margin: 0 0.521vw;
    }

    /deep/.el-input__inner {
        border: none;
        padding: 0;
        height: 3.333vh;
        box-sizing: border-box;
    }
}

.clothing_size {
    border: 0.052vw solid #DCDFE6;

    /deep/.el-select-dropdown {
        top: 21.778vh !important;
    }
}

.negative_upload {
    border: 0.052vw solid #DCDFE6;

    /deep/.el-select-dropdown {
        top: 21.778vh !important;
    }
}

.aFu {
    /deep/img {
        max-width: 4.167vw;
        max-height: 3.704vh;
    }
}
</style>