<template>
  <div class="order-mask" v-if="datavalue.flag">
    <!-- 预约信息查看 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div class="mask-title">
          <div></div>
          {{ datavalue.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="table filei">
        <!-- <div class="cen-table cloth">
                    <div class="cloth-item" v-for="item in themejson" :key="item.id">
                        <div class="cloth-name">{{ item.name }}</div>
                        <div class="cloth-num">{{ item.count }}</div>
                        <div class="cloth-foot">拍摄数量</div>
                    </div>
                </div> -->
        <div class="cen">
          <div class="cen-c">
            <div class="cen-c1"></div>
            <div class="cen-c2">拍摄主题</div>
          </div>

          <div class="cen-table cloth">
            <!-- <div class="cloth-item" v-for="item in tabledata" :key="item.id">
                    <div class="cloth-name">{{ item.name }}</div>
                    <div class="cloth-num">{{ item.count }}</div>
                    <div class="cloth-foot">拍摄数量</div>
                </div> -->
            <div class="cloth-item2" v-for="item in tabledata" :key="item.id">
              <div class="cloth-name">{{ item.name }}</div>
              <div class="cloth-name1">
                <div class="cloth-name1_l">拍摄风格：</div>
                <div class="cloth-name1_r">
                  {{ item.sname }}
                </div>
              </div>
              <div class="cloth_table">
                <table class="cloth_table_type">
                  <tr class="cloth_table_head">
                    <td class="cloth_table_item2">拍摄风格</td>
                    <td class="cloth_table_item3">拍摄主题/数量</td>
                    <td class="cloth_table_item4">是否指定</td>
                  </tr>
                  <tr class="cloth_table_head cloth_table_list" v-for="ele in item.style" :key="ele.id">
                    <td class="cloth_table_item2">{{ ele.name }}</td>
                    <td class="cloth_table_item3" v-if="ele.radio == 2">
                      {{ ele.themename }}
                    </td>
                    <td class="cloth_table_item3" v-if="ele.radio == 1">
                      {{ ele.count }}
                    </td>
                    <td class="cloth_table_item4">
                      {{ ele.radio == "1" ? "否" : "是" }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <!-- <div class="cloth-item">
                    <div class="cloth-name">亲子照</div>
                    <div class="cloth-num">1</div>
                    <div class="cloth-foot">拍摄数量</div>
                </div>
                <div class="cloth-item">
                    <div class="cloth-name">孕妇照</div>
                    <div class="cloth-num">1</div>
                    <div class="cloth-foot">拍摄数量</div>
                </div> -->
          </div>

          <div class="cen-c">
            <div class="cen-c1"></div>
            <div class="cen-c2">服装信息</div>
          </div>
          <div class="tablefather" style="width: 99%; margin-top: 1vw">
            <el-table class="center_table" ref="multipleTable" height="35.6vh" :data="tableDatalist"
              tooltip-effect="dark" border :header-cell-style="{
                width: 81.823 + 'vw',
                height: 5.185 + 'vh',
                background: '#F9F9F9',
                fontSize: 0.729 + 'vw',
                textAlign: 'center',
              }" @selection-change="handleSelectionChange">
              <el-table-column type="selection" min-width="5.4px" align="center">
              </el-table-column>
              <el-table-column align="center" prop="bianhao" label="标准图库ID" show-overflow-tooltip
                min-width="23.0px"></el-table-column>
              <el-table-column align="center" prop="name" label="标准片名称" show-overflow-tooltip
                min-width="9.9px"></el-table-column>
              <el-table-column align="center" prop="model_image" label="客户可见标准片" show-overflow-tooltip
                min-width="23.0px">
                <template slot-scope="scope">
                  <div class="aFu" @contextmenu="disableRightClick">
                    <el-image style="
                        max-width: 4.167vw !important;
                        max-height: 3.704vh !important;
                        width: auto !important;
                        height: auto !important;
                      " :src="imgurl + scope.row.images" @click="bigimgclick(imgurl + scope.row.images)"
                      v-if="scope.row.images != null">
                    </el-image>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="theme" label="标准片类型" show-overflow-tooltip min-width="18.0px">
              </el-table-column>
              <el-table-column align="center" prop="sex" label="性别" show-overflow-tooltip
                min-width="13.0px"></el-table-column>
              <el-table-column align="center" prop="size" label="衣码" show-overflow-tooltip
                min-width="16.0px"></el-table-column>
              <el-table-column align="center" prop="source" label="标准片来源" show-overflow-tooltip
                min-width="13.0px"></el-table-column>
            </el-table>
          </div>

          <!-- 底部分页 -->
          <!-- <div class="footer-page" style="width: 85%">
            <span>共{{ tableData.total_count }}条</span>
            <el-pagination popper-class="paging" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              background :current-page="page" :page-sizes="[10, 20, 30, 40]" :page-size="count"
              layout="sizes, prev, pager, next, jumper" :total="tableData.total_count" :pager-count="5">
            </el-pagination>
          </div> -->
        </div>
        <!-- 预览 -->
        <ImgPreview :data="imgpreviewObj" @imgpreviewCall="imgpreviewCall"></ImgPreview>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MeservationCell",
  props: ["datavalue"],
  data() {
    return {
      textarea: "",
      themejson: [],

      id: "",
      page: 1,
      count: 10,
      tableDatalist: [],
      tableData: [],
      tabledata: [],
      //分页
      currentPage: 4,

      themestype: [],

      shopname: "",
      imgpreviewObj: {
        flag: false,
        src: "",
        shopname: "",
      },

      themeslist: [],
    };
  },
  mounted() { },
  methods: {
    //取消弹窗
    handleRefund() {
      let obj = {
        flag: false,
      };
      this.$emit("MeservationCell", obj);
    },
    handleSubmit() {
      this.handleRefund();
    },
    //得到当前账号所关联的店铺信息
    async indexGetinfo() {
      let res = await this.$api.indexGetinfo();
      if (res.code == 1) {
        this.shopname = res.data.shopname;
      }
    },
    // 阻止默认右键菜单的显示
    disableRightClick(event) {
      event.preventDefault();
      return false;
    },
    bigimgclick(url) {
      this.imgpreviewObj.flag = true;
      this.imgpreviewObj.src = url;
      this.imgpreviewObj.shopname = this.shopname;
    },
    imgpreviewCall() {
      this.imgpreviewObj.flag = false;
    },
    handleSizeChange() { },
    handleSelectionChange() { },
    handleCurrentChange() { },
    async standardGettheme() {
      let res = await this.$api.standardGettheme();
      if (res.code == 1) {
        this.themestype = res.data;
      }
    },
    //得到拍摄类型
    async gettheme() {
      let res = await this.$api.typeIndex();
      if (res.code == 1) {
        this.themeslist = res.data;
      }
    },
    async orderDetail() {
      let rr = await this.gettheme();
      const data = {
        id: this.id,
      };
      let res = await this.$api.orderDetail(data);
      this.tabledata = res.data.themejson;
      this.tabledata.forEach((item) => {
        let arrid = [];
        let arrname = [];

        this.themeslist.forEach((ele) => {
          if (item.id == ele.id) {
            item.child = ele.sec;
          }
        });
        item.style.forEach((ele) => {
          ele.themescascader = ele.theme_id.split(",");
          if (ele.theme_id != "") {
            ele.radio = "2";
          } else {
            ele.radio = "1";
          }
          arrid.push(ele.id);
          arrname.push(ele.name);

          this.themeslist.forEach((v) => {
            v.sec.forEach((vv) => {
              if (vv.id == ele.id) {
                ele.child = vv.third;
              }
            });
          });
          if (ele.theme_id) {
            let aaaaaa = ele.theme_id.split(",");
            let themenamearr = [];
            ele.child.forEach((vchild) => {
              aaaaaa.forEach((vaaaa) => {
                if (vchild.id == vaaaa) {
                  themenamearr.push(vchild.name);
                }
              });
            });
            ele.themename = themenamearr.join(",");
          }
        });
        item.styleids = [...new Set(arrid)];
        item.sname = [...new Set(arrname)].join("/");

        // 多个id一样
        item.style.forEach((evvv, evvvindex) => {
          evvv.copeindex = evvvindex;
          let arrv = arrid.slice(0, evvvindex);
        });
      });
    },
    async orderProgresslist() {
      let res = await this.$api.orderProgresslist({ id: this.id });
      if (res.code == 1) {
        this.tableDatalist = res.data;
        this.tableDatalist.forEach((element) => {
          if (element.min_size && element.min_size) {
            element.clothing = element.min_size + "-" + element.max_size;
          } else {
            if (element.min_size) {
              element.clothing = element.max_size;
            } else if (element.min_size) {
              element.clothing = element.min_size;
            } else {
              element.clothing = "";
            }
          }

          if (element.images) {
            element.images = element.images.split(",")[0] + "";
          }

          // if (element.theme_ids.charAt(0) == ',') {
          //     element.theme_ids = element.theme_ids.slice(1, element.theme_ids.length)
          // }
          // if (element.theme_ids.charAt(element.theme_ids.length - 1) == ',') {
          //     element.theme_ids = element.theme_ids.slice(0, element.theme_ids.length - 1)
          // }
          // element.theme_ids = element.theme_ids.split(',')

          this.themestype.forEach((item) => {
            if (element.theme_id == item.id) {
              element.theme_id = item.name;
            }
          });
        });
      }
    },
  },
  watch: {
    datavalue: {
      deep: true,
      handler(newvalue, oldvalue) {
        // this.themejson = newvalue.themejson;

        if (newvalue.flag) {
          this.id = newvalue.id;
          this.standardGettheme();

          this.orderProgresslist();
          this.indexGetinfo();
          this.orderDetail();
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/mask.less");
@import url("@/assets/css/table.less");

.mask-main {
  width: 76vw !important;
  min-height: 50.926vh;

  .cen-table {
    margin-left: 0;
  }
}

.filei .cloth-item:nth-child(3n) {
  margin-right: 0;
}

// @import url("@/assets/css/table.css");
//@import url("@/assets/css/table.less");

div {
  box-sizing: border-box;
}

.tablefather {
  margin-right: 0 !important;
}

.cloth {
  display: flex;
}

.filei .cen-table {
  margin-right: 1.042vw;
}

.filei .cen {
  padding-bottom: 0;
}

.cloth-item {
  width: 9.375vw;
  height: 16.667vh;
  background-color: #ffffff;
  border-radius: 0.521vw;
  border: solid 0.104vw #2c72ff;
  margin-right: 1.354vw;
  margin: 0.926vh 0.521vw;
  text-align: center;

  .cloth-name {
    color: #ffffff;
    height: 5.185vh;
    line-height: 5.185vh;
    background-color: #2c72ff;
  }

  .cloth-num {
    font-size: 3.125vw;
    color: #ff0000;
    height: 7.407vh;
    line-height: 7.407vh;
  }

  .cloth-foot {
    height: 4.167vh;
    line-height: 4.167vh !important;
    border-top: solid 0.104vw #2c72ff;
  }
}

//分页
.footer-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999999;
  font-size: 0.833vw;
  margin-top: 0.926vh;
  padding: 0 1.042vw;
  // margin-top: 100px;

  span {
    color: #999;
  }
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2c72ff;
}

/deep/.el-pager li {
  width: 1.823vw;
  height: 3.241vh;
  line-height: 3.241vh;
  font-size: 0.833vw;
}

/deep/.el-pagination button {
  width: 1.823vw;
  height: 3.241vh;
  line-height: 3.241vh;
  font-size: 0.833vw;
}

/deep/ .el-pagination {
  display: flex;
  align-items: center;
}

/deep/.aFu .el-image__inner {
  width: auto !important;
  max-width: 4.688vw !important;
  height: auto !important;
  max-height: 4.167vh !important;
}

.cloth-item2 {
  width: 34.896vw;
  min-height: 22.083vw;
  background-color: #f9f9f9;
  border-radius: 0.521vw;
  margin-left: 0.625vw;
  margin-top: 0.7813vw;

  .cloth-name {
    width: 100%;
    height: 2.917vw;
    line-height: 2.917vw;
    font-size: 0.833vw;
    font-weight: bold;
    color: #ffffff;
    background-color: #2c72ff;
    border-radius: 0.521vw 0.521vw 0vw 0vw;
    padding-left: 1.302vw;
  }

  .cloth-name1 {
    width: 100%;
    height: 3.6458vw;
    display: flex;
    padding: 0.625vw 1.094vw 0.625vw 0.99vw;

    .cloth-name1_l {
      width: 4.844vw;
      height: 2.396vw;
      line-height: 2.396vw;
      text-align: left;
      font-size: 0.833vw;
      color: #333333;
    }

    /deep/.cloth-name1_r {
      width: 27.969vw;
      height: 2.396vw;
      line-height: 2.396vw;
      text-align: center;
      background-color: #fff;
      border-radius: 0.313vw;
      border: solid 0.052vw #e1e1e1;

      .el-cascader {
        width: 100%;
        height: 100%;

        .el-input {
          height: 100%;

          .el-input__inner {
            height: 100% !important;
            border: 0.0521vw solid #dcdfe6;
          }
        }
      }
    }
  }

  .cloth_table {
    width: 100%;
    padding: 0 1.042vw 0.677vw 1.042vw;

    .cloth_table_type {
      border: 0;

      .cloth_table_head {
        width: 100%;
        width: 32.813vw;
        // height: 2.917vw;
        background-color: #f9f9f9;
        display: flex;
        align-items: center;

        .cloth_table_item1 {
          width: 4.896vw;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
        }

        .cloth_table_item2 {
          width: 7.917vw;
        }

        .cloth_table_item3 {
          // width: 17.135vw;
          flex: 1;
        }

        .cloth_table_item4 {
          width: 8.49vw;
        }

        td {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.833vw;
          font-weight: bold;
          color: #333333;
        }
      }

      .cloth_table_list {
        .cloth_table_item1 {
          div {
            color: #2c72ff;
            cursor: pointer;
          }

          div:hover {
            text-decoration: underline;
          }
        }

        td {
          font-weight: 500;
        }
      }
    }
  }
}

.cloth-item2:nth-child(2n + 1) {
  margin-left: 0;
}
</style>